export default function getCurrentTime() {
  const date = new Date();

  const year = date.getFullYear().toString();
  let month = String(date.getMonth() + 1);
  let day = date.getDate().toString();
  let hours = date.getHours().toString();
  let minutes = date.getMinutes().toString();
  let seconds = date.getSeconds().toString();

  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  if (hours.length === 1) {
    hours = '0' + hours;
  }
  if (minutes.length === 1) {
    minutes = '0' + minutes;
  }
  if (seconds.length === 1) {
    seconds = '0' + seconds;
  }

  return year + month + day + hours + minutes + seconds;
}

export function getNumberTime(date: Date) {
  const year = date.getFullYear().toString().slice(2);
  let month = String(date.getMonth() + 1);
  let day = date.getDate().toString();

  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  return year + month + day;
}

export function getNumberFullTime(date: Date) {
  const year = date.getFullYear().toString();
  let month = String(date.getMonth() + 1);
  let day = date.getDate().toString();

  if (month.length === 1) {
    month = '0' + month;
  }
  if (day.length === 1) {
    day = '0' + day;
  }
  return year + month + day;
}
