import { IExcelResponseEx } from '@/types/refund';
import { useEffect, useState } from 'react';
import {
  Cell,
  Col,
  PrintPageWrapper,
  Row,
  Table,
  Title,
} from '../refund/ExcelTable';
import { getComma, removeComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import styled from 'styled-components';

type Props = {
  data: IExcelResponseEx[] | undefined;
};

const PorteRecipt = ({ data }: Props) => {
  const [totalRecipt, setTotalRecipt] = useState({
    payment: '',
    originRefund: '',
    ktpRefund: '',
    totalRefund: '',
  });

  useEffect(() => {
    let payment = 0;
    let originRefund = 0;
    if (data) {
      data.map((item) => {
        payment += removeComma(item.totalPayment);
        originRefund += removeComma(item.totalRefund);
      });
      setTotalRecipt({
        payment: getComma(payment),
        originRefund: getComma(originRefund),
        ktpRefund: getComma(getRefundAmount(payment) - originRefund),
        totalRefund: getComma(getRefundAmount(payment)),
      });
    }
  }, [data]);

  return (
    <PrintPageWrapper>
      <Title>■ 통합 시술 내역 확인서</Title>
      <Table>
        <Row>
          <Cell flex='4' bold>
            {`통합 시술 내역 확인서\nCertificate of Total Selling Medical Service`}
          </Cell>
        </Row>
        <Row height={200}>
          <Cell bold>{`의료기관\nMedical Institution`}</Cell>
          <Col flex='4'>
            <Row>
              <Cell>{` 의료기관 명칭\nName of Institution`}</Cell>
              <Cell>{`사업자 등록번호\nRegistration No.`}</Cell>
              <Cell>{`외국인환자 유치기관 등록번호\nRegistration No.`}</Cell>
              <Cell>{`전화번호\nTelephone No.`}</Cell>
            </Row>
            <Row>
              <Cell>{data?.[0].medicalName}</Cell>
              <Cell>{data?.[0].medicalBusinessNum}</Cell>
              <Cell>{data?.[0].medicalRegistNum}</Cell>
              <Cell>{data?.[0].medicalPhoneNum}</Cell>
            </Row>
            <Row>
              <Cell>{`대표자 명\nRepresentative’s Name`}</Cell>
              <Cell flex='3'>{`의료기관 소재지\nAddress`}</Cell>
            </Row>
            <Row>
              <Cell>{data?.[0].medicalRepresentative}</Cell>
              <Cell flex='3'>{data?.[0].medicalAddress}</Cell>
            </Row>
          </Col>
        </Row>

        <Row height={100}>
          <Cell bold>{`환자\nPatient`}</Cell>
          <Col flex='4'>
            <Row>
              <Cell>{`성명\nName in full`}</Cell>
              <Cell>{`생년월일\nDate of Birth`}</Cell>
              <Cell bold>{`국적\nNationality`}</Cell>
              <Cell bold>{`여권 등의 번호\nPassport or ID Card No.`}</Cell>
            </Row>
            <Row>
              <Cell>{data?.[0].patientName}</Cell>
              <Cell>{data?.[0].patientBirth}</Cell>
              <Cell>{data?.[0].patientNation}</Cell>
              <Cell>{data?.[0].patientPassport}</Cell>
            </Row>
          </Col>
        </Row>

        <Row height={100}>
          <Col flex='4'>
            <Row>
              <Cell bold backColor>{`의료보건용역 공급일\nDate of Sale`}</Cell>
              <Cell
                bold
                backColor
              >{`총 시술 금액\nTotal Treatment Amount`}</Cell>
              <Cell bold backColor>{`기본 환급액\nAmount of Refund`}</Cell>
              <Cell
                bold
                backColor
              >{`KTP 환급 프로모션\nKTP Refund Promotion`}</Cell>
              <Cell bold backColor>{`총 환급액\nTotal Amount of Refund`}</Cell>
            </Row>
            <Row>
              <Cell backColor>{data?.[0].saleDate}</Cell>
              <Cell backColor>{totalRecipt.payment}</Cell>
              <Cell backColor>{totalRecipt.originRefund}</Cell>
              <Cell backColor>{totalRecipt.ktpRefund}</Cell>
              <Cell backColor>{totalRecipt.totalRefund}</Cell>
            </Row>
          </Col>
        </Row>
      </Table>
    </PrintPageWrapper>
  );
};

export default PorteRecipt;
