import {
  excelDownload,
  refundDetail,
  refundSuperiorDetail,
} from '@/api/refund';
import { IRefundDetailResponse } from '@/types/refund';
import { AxiosError } from 'axios';
import { format } from 'date-fns';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useAppSelector } from './useReduxHooks';

export const useRefndDetail = () => {
  const [size, setSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [totalPage, setTotalPage] = useState(0);
  const [enabled, setEnabled] = useState(false);
  const { hospitals, medicalName } = useAppSelector((state) => state.user);
  const { filter } = useAppSelector((state) => state.refund);

  const params = {
    page: pageNumber,
    size,
    productType: filter.productType.name,
    subHospitalId: filter.hospitals.hospitalId,
    startDate: format(filter.startDate, 'yyyy-MM-dd'),
    endDate: format(filter.endDate, 'yyyy-MM-dd'),
    orderBy: filter.orderBy,
  };

  const excelQueryString = {
    productType: filter.productType.name,
    startDate: format(filter.startDate, 'yyyy-MM-dd'),
    endDate: format(filter.endDate, 'yyyy-MM-dd'),
  };

  useEffect(() => {
    setEnabled(true);
    return () => setEnabled(false);
  }, [filter]);

  const { data, refetch } = useQuery<IRefundDetailResponse, AxiosError>(
    ['refundDetail', params, filter],
    () =>
      hospitals.length > 0
        ? refundSuperiorDetail(params)
        : refundDetail(params),
    {
      onSuccess: (res) => {
        setTotalPage(res.totalPages);
      },
      retry: false,
      enabled,
      keepPreviousData: true,
    },
  );

  const { refetch: excelDown } = useQuery<any, AxiosError>(
    ['excelFileDownload', excelQueryString],
    async () => await excelDownload(excelQueryString),
    {
      retry: false,
      enabled: false,
      onSuccess: (s3Url) => {
        const blob = new Blob([s3Url]);
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = `${medicalName}_${format(
          new Date(filter.startDate),
          'yyyyMMdd',
        )}-${format(new Date(filter.endDate), 'yyyyMMdd')}.xlsx`;
        link.click();
        URL.revokeObjectURL(url);
      },
      onError: () => {
        alert(
          '다운로드에 실패하였습니다. 아래 연락처로 문의바랍니다.\nT. 02-6275-8011 (평일 오전 8시 - 오후 5시)',
        );
      },
    },
  );

  return {
    data,
    totalPage,
    refetch,
    pageNumber,
    setPageNumber,
    setSize,
    excelDown,
  };
};
