import React, { Dispatch, SetStateAction } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { ExpireDateType } from './ExpireDateModal';

type MonthDropDownItemProps = {
  item: { label: string; value: string };
  idx: number;
  selectedItemIndex: number;
  setExpireDate: Dispatch<SetStateAction<ExpireDateType>>;
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>;
};

function MonthDropDownItem({
  item,
  idx,
  selectedItemIndex,
  setExpireDate,
  setIsDropDownOpen,
}: MonthDropDownItemProps) {
  const { setValue } = useFormContext();

  return (
    <DropDownItem
      isSelected={selectedItemIndex === idx}
      onMouseDown={() => {
        setExpireDate((prev) => ({
          ...prev,
          month: item,
        }));
        setValue('birthMonth', item);
        setIsDropDownOpen(false);
      }}
    >
      {item.label}
    </DropDownItem>
  );
}

const DropDownItem = styled.li<{ isSelected?: boolean }>`
  display: flex;
  justify-content: center;
  color: ${(props) => props.isSelected && '#FFF'};
  background-color: ${(props) => props.isSelected && 'rgb(38, 132, 255)'};
  padding: 10px;
  :hover {
    background-color: rgb(38, 132, 255);
    color: #fff;
  }
`;

export default MonthDropDownItem;
