import styled from 'styled-components';

import MedicalInfoContent from '@/components/medicalInfo/MedicalContent';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function Medical() {
	const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

	return (
		<Container>
			{isExpiryWarning ? (
				<WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
			) : (
				<RenewalNotification />
			)}
			<MedicalInfoContent />
			<Footer />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 1200px;
	height: 100vh;
	flex: 1;
	overflow-y: scroll;
	background-color: ${(props) => props.theme.blueColors.scale3};
`;

export default Medical;
