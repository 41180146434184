import { Dispatch, SetStateAction } from 'react';
import styled, { css } from 'styled-components';

import '@/styles/calendar.css';
import LoadingView from '@/components/common/LoadingView';
import useActivateKioskVideo from '@/hooks/useActivateKioskVideo';

type Props = {
  fileName: string;
  kioskVideoId: number;
  onClose: () => void;
  setIsActive: Dispatch<SetStateAction<boolean>>;
};
function PlayModal({ fileName, kioskVideoId, onClose, setIsActive }: Props) {
  const { mutate, isLoading } = useActivateKioskVideo({
    callback: () => {
      onClose();
      setIsActive(true);
    },
  });

  const onPlay = () => {
    mutate({ kioskVideoId, activate: true });
  };

  return (
    <BackDrop>
      {isLoading ? (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>동영상 재생</Title>
          <Content>
            선택하신 영상을 키오스크에서 재생합니다. 현재 영상은 재생을
            중단합니다.
          </Content>

          <FileName>{fileName}</FileName>
          <ButtonWrapper>
            <Button onClick={onClose}>취소</Button>
            <Button colored onClick={onPlay}>
              재생
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </BackDrop>
  );
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 270px;
  padding: 36px 40px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;
const LoadingContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;
const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
`;
const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;
const FileName = styled.div`
  color: #5f6165;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  background-color: #f5f6f7;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
  margin-top: 16px;
`;
const Button = styled.button<{ colored?: boolean }>`
  ${(props) =>
    props.colored
      ? css`
          background-color: #ff2d55;
          color: #ffffff;
        `
      : css`
          background-color: #e5e6e8;
          color: #3a3b3e;
        `}
  font-size: 14px;
  line-height: 150%;
  padding: 15px 42px;
  font-weight: 600;
  border-radius: 6px;
  flex: 1;
  :hover {
    opacity: 0.6;
  }
`;
export default PlayModal;
