import { AxiosError } from 'axios';
import { useEffect } from 'react';
import { useMutation } from 'react-query';
import { useNavigate, useParams } from 'react-router';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';

import { signInWithAccessToken } from '@/api/user';
import { useAppDispatch } from '@/hooks/useReduxHooks';
import { updateUserInfo } from '@/store/modules/user';
import { AutoSignInPayload, ISignInResponse } from '@/types/user';
import LoadingView from '@/components/common/LoadingView';

type Params = {
  accessToken: string;
};
function AutoLogin() {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const accessToken = location?.state?.token;
  const navigate = useNavigate();

  const { mutate } = useMutation<
    ISignInResponse,
    AxiosError<any>,
    AutoSignInPayload
  >((payload) => signInWithAccessToken(payload), {
    retry: false,
    onSuccess: (res) => {
      localStorage.setItem('loginToken', res.accessToken);
      localStorage.setItem('name', res.hospitalName);
      localStorage.setItem('profileImg', res.hospitalImagePath || '');
      dispatch(
        updateUserInfo({
          isLogged: true,
          productTypes: res.productTypes,
          hospitalType: res.hospitalType,
          hospitalId: res.hospitalId,
          isKioskStore: res.kioskId,
          hospitals: res.hospitals || [],
        })
      );
      navigate('/');
    },
    onError: (error) => {
      alert(`서버와 통신이 원활하지 않습니다.`);
      navigate('/');
    },
  });
  useEffect(() => {
    if (!accessToken) {
      navigate('/');
    } else {
      mutate({ accessToken });
    }
  }, []);

  return (
    <Container>
      <LoadingView />
    </Container>
  );
}
const Container = styled.div`
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
export default AutoLogin;
