import styled from 'styled-components';
import FlexWrap from './FlexWrap';

function LoadingView() {
  return (
    <>
      <FlexWrap dir='column' alignItems='center'>
        <LoadingSpinner />
      </FlexWrap>
    </>
  );
}

const LoadingSpinner = styled.div`
  width: 24px;
  height: 24px;
  border: 4px solid white;
  border-top: 4px solid #246cf6;
  border-radius: 50%;
  animation: spin 700ms linear infinite;
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;
export default LoadingView;
