import { INotice, INoticeResponse } from '@/types/notice';
import { IParams } from '@/types/refund';
import api from '..';

// 공지사항
export const notice = async (params: IParams) => {
  const res = await api.getAxios<INoticeResponse, IParams>(
    `/user/notice`,
    params
  );
  return res.data;
};

// 공지사항 상세조회
export const noticeDetailInfo = async (noticeIndex: number) => {
  const res = await api.getAxios<INotice[], number>(
    `/user/notice/detail/${noticeIndex}`
  );
  return res.data;
};
