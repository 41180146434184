import React from 'react';
import styled from 'styled-components';

interface IProps {
  text?: string;
  absolute?: boolean;
  margin?: string;
}

function ErrorMessage({ text, margin, absolute = false }: IProps) {
  return (
    <>
      {text && (
        <Text absolute={absolute} margin={margin}>
          {text}
        </Text>
      )}
    </>
  );
}

const Text = styled.span<{ absolute?: boolean; margin?: string }>`
  position: ${(props) => props.absolute && 'absolute'};
  margin: ${(props) => props.margin && props.margin};
  bottom: -20px;
  left: 20%;
  color: ${(props) => props.theme.priamryColors.error};
  z-index: 10;
`;

export default ErrorMessage;
