import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
  useEffect,
  useRef,
} from 'react';
import {
  Controller,
  FieldValues,
  SubmitHandler,
  useFormContext,
} from 'react-hook-form';
import styled from 'styled-components';

import passportImg from '@/assets/refund/passport.png';
import { birthMonthList } from '@/constants/refund';
import FlexWrap from '../common/FlexWrap';
import LoadingView from '../common/LoadingView';
import Typography from '../common/Typography';
import ErrorMessage from '../common/ErrorMessage';
import Icon from '../common/Icon';
import MonthDropDownItem from './MonthDropDownItem';
import upArrowIcon from '@/assets/common/upArrow.png';
import downArrowIcon from '@/assets/common/downArrow.png';
export type ExpireDateType = {
  year: string;
  month: { value: string; label: string };
  day: string;
};

interface IExpireDateModalProps {
  isLoading: boolean;
  expireDate: ExpireDateType;
  setExpireDate: Dispatch<SetStateAction<ExpireDateType>>;
  onSubmit: SubmitHandler<FieldValues>;
  selectedItemIndex: number;
  setSelectedItemIndex: Dispatch<SetStateAction<number>>;
  isDropDownOpen: boolean;
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>;
  inputValueRef: MutableRefObject<{ label: string; value: string }>;
  dropDownRef: RefObject<HTMLUListElement>;
}

function ExpireDateModal({
  onSubmit,
  expireDate,
  setExpireDate,
  isLoading,
  inputValueRef,
  selectedItemIndex,
  setSelectedItemIndex,
  isDropDownOpen,
  setIsDropDownOpen,
  dropDownRef,
}: IExpireDateModalProps) {
  const { year, day } = expireDate;
  const {
    formState: { errors },
    control,
    handleSubmit,
    setFocus,
  } = useFormContext();

  const dayRef = useRef<HTMLInputElement>(null);

  // 생년월일 숫자만 입력가능
  const dateTextFilter = (value: string, regex: RegExp) => {
    if (value.length < 2) {
      return value.replace(regex, '');
    }
    return value.replace(/[^0-9]/g, '');
  };

  useEffect(() => {
    if (dayRef.current) {
      dayRef.current.focus();
    }
  }, []);

  return (
    <BackDrop>
      <Container>
        <Typography
          size='21px'
          fontWeight='700'
          lineHeight='25px'
          padding='20px'
        >
          외국인 관광객 정보 확인
        </Typography>
        <DivideLine />
        <Content>
          <Typography size='16px' fontWeight='500' lineHeight='24px'>
            고객님의 생년월일이 여권과 일치한지 확인해주세요.
          </Typography>
          <ImgWrapper>
            <ImgContainer>
              <Image src={passportImg} width='652px' height='246px' />
              <InputContainer>
                <Typography
                  size='18px'
                  fontWeight='700'
                  lineHeight='24px'
                  color='#0054A2'
                >
                  Date of Birth. 생년월일
                </Typography>
                <BirthContainer>
                  <BirthDate flex={1}>
                    <BirthInput
                      ref={dayRef}
                      value={day}
                      maxLength={2}
                      placeholder='DD'
                      autoComplete='off'
                      onChange={(e) =>
                        setExpireDate((prev) => ({
                          ...prev,
                          day: dateTextFilter(e.target.value, /[^0-3]/g),
                        }))
                      }
                    />
                    <Typography>일</Typography>
                  </BirthDate>
                  <BirthDate flex={1.2}>
                    <Controller
                      name='birthMonth'
                      control={control}
                      render={() => (
                        <DropDownContainer
                          tabIndex={0}
                          onBlur={() => {
                            setIsDropDownOpen(false);
                            setSelectedItemIndex(-1);
                          }}
                          onFocus={() => {
                            inputValueRef.current.value = '';
                            setIsDropDownOpen(true);
                          }}
                          onKeyDown={(e) => {
                            if (e.key === 'Enter' || e.key === ' ') {
                              if (selectedItemIndex === -1) return;
                              const selectedItem =
                                birthMonthList[selectedItemIndex];
                              setExpireDate((prev) => ({
                                ...prev,
                                month: selectedItem,
                              }));
                              setIsDropDownOpen(!isDropDownOpen);
                              setFocus('birthDay');
                            } else if (e.key === 'ArrowUp') {
                              e.preventDefault();
                              setSelectedItemIndex((prev) =>
                                Math.max(prev - 1, 0)
                              );
                            } else if (e.key === 'ArrowDown') {
                              e.preventDefault();
                              setSelectedItemIndex((prev) =>
                                Math.min(prev + 1, birthMonthList.length - 1)
                              );
                            } else {
                              // 월을 직접 입력시 저장되도록
                              inputValueRef.current.value += e.key;
                              birthMonthList.forEach((item) => {
                                if (
                                  inputValueRef.current.value === item.value
                                ) {
                                  setExpireDate((prev) => ({
                                    ...prev,
                                    month: item,
                                  }));
                                  setIsDropDownOpen(!isDropDownOpen);
                                  setFocus('birthDay');
                                }
                              });
                            }
                          }}
                        >
                          <DropDownHeader
                            isValue={Boolean(expireDate.month.value)}
                          >
                            {expireDate.month.label || 'MM'}
                            <>
                              <Icon
                                width='12px'
                                height='12px'
                                imgUrl={
                                  isDropDownOpen ? upArrowIcon : downArrowIcon
                                }
                              />
                            </>
                          </DropDownHeader>
                          {isDropDownOpen && (
                            <DropDownList ref={dropDownRef}>
                              {birthMonthList.map((item, index) => (
                                <MonthDropDownItem
                                  key={index}
                                  idx={index}
                                  item={item}
                                  setExpireDate={setExpireDate}
                                  selectedItemIndex={selectedItemIndex}
                                  setIsDropDownOpen={setIsDropDownOpen}
                                />
                              ))}
                            </DropDownList>
                          )}
                        </DropDownContainer>
                      )}
                    />
                    <Typography>월</Typography>
                  </BirthDate>
                  <BirthDate flex={1}>
                    <BirthInput
                      value={year}
                      maxLength={4}
                      placeholder='YYYY'
                      autoComplete='off'
                      onChange={(e) =>
                        setExpireDate((prev) => ({
                          ...prev,
                          year: dateTextFilter(e.target.value, /[^1-2]/g),
                        }))
                      }
                    />
                    <Typography>년</Typography>
                  </BirthDate>
                </BirthContainer>
              </InputContainer>
            </ImgContainer>
          </ImgWrapper>
        </Content>
        <DivideLine />
        <FlexWrap
          alignItems='center'
          justifyContent='flex-end'
          padding='24px 20px'
        >
          {errors.birthYear && (
            <ErrorMessage text={String(errors.birthYear.message)} />
          )}
          <Button onClick={handleSubmit(onSubmit)}>
            {isLoading ? <LoadingView /> : '확인'}
          </Button>
        </FlexWrap>
      </Container>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;
const Container = styled.div`
  width: 1030px;
  height: 576px;
  background-color: #fff;
  border-radius: 12px;
`;
const Content = styled.div`
  padding: 28px 20px;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 732px;
  height: 310px;
  margin-top: 28px;
  border: 2px solid #95b6c0;
  border-radius: 12px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e6e8;
`;
const Image = styled.img`
  object-fit: contain;
`;
const Button = styled.button`
  width: 100px;
  height: 42px;
  border-radius: 2px;
  color: #ffffff;
  background-color: #030303;
  margin-left: 5px;
`;
const InputContainer = styled.div`
  position: absolute;
  top: 118px;
  left: 212px;
  width: 400px;
  height: 70px;
  background-color: #fff;
`;
const BirthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 80%;
  height: 48px;
  margin-bottom: 5px;
`;

const BirthDate = styled.div<{ flex: number }>`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
`;

const BirthInput = styled.input`
  width: 115px;
  height: 42px;
  padding-left: 10px;
  border-radius: 4px;
  margin-right: 3px;
  outline: none;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  color: #212121;
  font-size: 16px;
  :disabled {
    background-color: ${(props) => props.theme.grayColors.scale4};
    color: gray;
  }
  ::placeholder {
    color: #cbccce;
  }
  :focus {
    border-color: #246cf6;
  }
`;
const DropDownContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin-right: 3px;
  border: 2px solid #bdbdbd;
  border-radius: 4px;
  height: 42px;
  :focus {
    border: 2px solid #246cf6;
  }
`;
const DropDownHeader = styled.div<{ isValue: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* position: relative; */
  width: 150px;
  color: ${(props) => (props.isValue ? '#212121' : '#BDBDBD')};
  padding: 0 10px;
`;
const DropDownList = styled.ul`
  position: absolute;
  margin-top: 10px;
  top: 50px;
  width: 100%;
  max-height: 300px;
  border: 1px solid #cdccce;
  overflow: auto;
  z-index: 10;
  background-color: #fff;
  :focus {
    background-color: #246cf6;
  }
`;
export default ExpireDateModal;
