import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import Button from '../common/Button';
import Cancle from '../common/Cancle';

interface IProps {
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

function LoginModal({ setIsModal }: IProps) {
  return (
    <Container>
      <Header>
        <Title>아이디/비밀번호 찾기</Title>
        <Cancle width='18px' height='18px' setIsModal={setIsModal} />
      </Header>
      <Content>
        아이디는 의료기관의 사업자등록번호 혹인 법인번호입니다.
        <br />
        비밀번호를 분실하였을 경우, 상담센터로 문의주세요. (상담센터:
        02-6275-8011)
      </Content>
      <DivideLine />
      <ButtonWrapper>
        <Button
          text='확인'
          color='#FFFFFF'
          backgroundColor='#212121'
          width='100px'
          padding='13px 15px'
          borderRadius='4px'
          onClick={() => setIsModal(false)}
        />
      </ButtonWrapper>
    </Container>
  );
}

const Container = styled.div`
  position: absolute;
  padding: 36px 40px;
  border-radius: 8px;
  filter: ${(props) => props.theme.boxShadow};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 280px;
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const Content = styled.p`
  margin-top: 18px;
`;

const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 28px;
  background-color: ${(props) => props.theme.grayColors.scale4};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 26px;
`;

export default LoginModal;
