import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import styled from 'styled-components';
import { refundCancle } from '@/api/refund';
import refundNotImage from '@/assets/refund/refundNot.png';
import {
  RefundGraphFullPaymentHeader,
  RefundGraphHeader,
} from '@/constants/refundGraph';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { useRefndDetail } from '@/hooks/useRefundDetail';
import { IRefundCanclePayload, IRefundDetailContent } from '@/types/refund';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';
import HospitalsFilter from '../common/HospitalsFilter';
import Modal from '../common/Modal';
import Paging from '../common/Paging';
import SupplyFilter from '../common/SupplyFilter';
import TableHeader from '../refund/PaymentComplete/TableHeader';
import RefundDetailItem from './RefundDetailItem';
import ViewSelector from '../common/ViewSelector';
import DateSelector from '../common/DateSelector';
import DateSortingFilter from './DateSortingFilter';

function RefundDetailList() {
  const [removeArray, setRemoveArray] = useState<number[]>([]);
  const {
    data,
    totalPage,
    refetch,
    pageNumber,
    setPageNumber,
    setSize,
    excelDown,
  } = useRefndDetail();
  const [isModal, setIsModal] = useState(false);
  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const actvieRefund = !!data && !!data.totalPages;
  const methods = useForm({
    defaultValues: {
      checked: false,
    },
  });

  const refundCancleMutation = useMutation<
    null,
    AxiosError,
    IRefundCanclePayload
  >('refundCancle', (payload) => refundCancle(payload), {
    retry: false,
    onSuccess: () => {
      refetch();
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const onClickRefundCancle = () => {
    const payload = {
      cancelList: removeArray,
    };
    refundCancleMutation.mutate(payload);
    setIsModal(false);
  };

  const onClickCancle = () => {
    if (removeArray.length === 0) {
      return;
    }
    setIsModal(true);
  };

  const onClickDownload = () => {
    excelDown();
  };

  return (
    <FormProvider {...methods}>
      <Header>
        <SortingWrapper>
          <SubTitle>의료용역 환급내역</SubTitle>
          <DateSortingFilter />
        </SortingWrapper>
        <FlexWrap dir='column' gap='10px' alignItems='flex-end'>
          <FlexWrap gap='10px'>
            <DateSelector isRefundDetail setPageNumber={setPageNumber} />
            <Button
              width='160px'
              padding='5px 35px'
              text='파일 다운로드'
              backgroundColor='#0b42ad'
              color='#FFFFFF'
              borderRadius='4px'
              onClick={onClickDownload}
            />
          </FlexWrap>
          <FlexWrap gap='10px' alignItems='center'>
            <ViewSelector setSize={setSize} setPageNumber={setPageNumber} />
            {hospitals.length > 0 && (
              <HospitalsFilter setPageNumber={setPageNumber} />
            )}
            <SupplyFilter setPageNumber={setPageNumber} />
            <Button
              width='160px'
              padding='5px 35px'
              text='환급 취소'
              backgroundColor='#0b42ad'
              color='#FFFFFF'
              borderRadius='4px'
              onClick={onClickCancle}
            />
          </FlexWrap>
        </FlexWrap>
      </Header>
      {actvieRefund ? (
        <>
          <TableHeader
            titleList={
              isAutoPayment ? RefundGraphHeader : RefundGraphFullPaymentHeader
            }
          />
          <List>
            {data.content.map((refund: IRefundDetailContent, index: number) => (
              <RefundDetailItem
                key={index}
                refund={refund}
                idx={index}
                currentPage={data.number}
                removeArray={removeArray}
                setRemoveArray={setRemoveArray}
              />
            ))}
          </List>
        </>
      ) : (
        <RefundNotContainer>
          <Image src={refundNotImage} />
          <Text>아직 환급된 내역이 없습니다.</Text>
        </RefundNotContainer>
      )}
      <Paging
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        totalPage={totalPage}
      />
      {isModal && (
        <Modal
          title='환급 취소'
          content='선택하신 내역을 환급 취소 하시겠습니까?'
          onCancle={() => setIsModal(false)}
          onComfirm={onClickRefundCancle}
        />
      )}
    </FormProvider>
  );
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 28px;
`;

const SortingWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const SubTitle = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  height: 32px;
`;

const List = styled.ul`
  margin-bottom: 30px;
`;

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayColors.scale2};
`;

export default RefundDetailList;
