import { createSlice } from '@reduxjs/toolkit';

const priceData = {
	price: '',
	supplyPrice: '',
	vatPrice: '',
};

const initialState = {
	totalPrice: '',
	totalRefund: '',
	filter: {
		startDate: new Date(),
		endDate: new Date(),
		productType: {
			value: '',
			name: '',
		},
		hospitals: {
			hospitalId: '',
			hospitalName: '',
		},
		orderBy: 'SUPPLY_DATE',
	},
	priceList: [priceData, priceData, priceData, priceData, priceData],
	cardPrice: '',
	cashPrice: '',
	isDirectPayment: false,
	isDirectPassport: false,
	birthDate: '',
	isScanned: false,
	temporaryStorage: {
		supplyDate: '',
		touristName: '',
		passportNumber: '',
		birthYear: '',
		birthMonth: '',
		birthDay: '',
		nationality: '',
		totalPrice: '',
		supply: [{ price: '', productType: '' }],
		paymentCash: '',
		paymentCard: '',
		tempInformationId: '',
	},
	isDropList: [{ isDrop: false }, { isDrop: false }, { isDrop: false }],
};

const refundSlice = createSlice({
	name: 'refund',
	initialState,
	reducers: {
		reset: () => initialState,
		addPriceData: (state, action) => {
			state.priceList.push(action.payload);
			state.isDropList.push({ isDrop: false });
		},
		removePriceData: (state, action) => {
			state.priceList.splice(action.payload.index, 1);
		},
		totalPrice: (state, action) => {
			state.totalPrice += action.payload.totalPrice;
		},
		birthDateUpdateState: (state, action) => {
			state.birthDate = action.payload;
		},
		setFilterSeletor: (state, action) => {
			return {
				...state,
				filter: {
					startDate: action.payload.startDate,
					endDate: action.payload.endDate,
					productType: state.filter.productType,
					hospitals: state.filter.hospitals,
					orderBy: state.filter.orderBy,
				},
			};
		},
		setFilterProductType: (state, action) => {
			return {
				...state,
				filter: {
					startDate: state.filter.startDate,
					endDate: state.filter.endDate,
					productType: action.payload,
					hospitals: state.filter.hospitals,
					orderBy: state.filter.orderBy,
				},
			};
		},
		setFilterHospital: (state, action) => {
			return {
				...state,
				filter: {
					startDate: state.filter.startDate,
					endDate: state.filter.endDate,
					productType: state.filter.productType,
					hospitals: action.payload,
					orderBy: state.filter.orderBy,
				},
			};
		},
		setFilterOrderBy: (state, action) => {
			return {
				...state,
				filter: {
					startDate: state.filter.startDate,
					endDate: state.filter.endDate,
					productType: state.filter.productType,
					hospitals: state.filter.hospitals,
					orderBy: action.payload,
				},
			};
		},
		updateRefundInfo: (state, action) => {
			return { ...state, ...action.payload };
		},
		setDirectPassport: (state, action) => {
			return {
				...state,
				isDirectPassport: action.payload,
			};
		},
		setDirectPaymnet: (state, action) => {
			return {
				...state,
				isDirectPayment: action.payload,
			};
		},
		dropListState: (state, action) => {
			state.isDropList[action.payload.index].isDrop = action.payload.isDrop;
		},
	},
});

export const {
	reset,
	addPriceData,
	removePriceData,
	totalPrice,
	setFilterSeletor,
	updateRefundInfo,
	setDirectPaymnet,
	setDirectPassport,
	setFilterProductType,
	birthDateUpdateState,
	setFilterHospital,
	dropListState,
	setFilterOrderBy,
} = refundSlice.actions;
export default refundSlice.reducer;
