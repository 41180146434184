import { IHomeCalendarPayload, IHomeCalendarResponae } from '@/types/home';
import api from '..';

// 홈 기간 데이터
export const homeCalendar = async (params: any) => {
  const { startDate, endDate, productType } = params;
  const res = await api.getAxios<IHomeCalendarResponae, any>(`/user/home`, {
    startDate,
    endDate,
    productType,
  });
  return res.data;
};

// 홈 상위 병원
export const homeSuperior = async (params: any) => {
  const { startDate, endDate, productType, subHospitalId } = params;
  const res = await api.getAxios<IHomeCalendarResponae, any>(`/superior/home`, {
    startDate,
    endDate,
    productType,
    subHospitalId,
  });
  return res.data;
};
