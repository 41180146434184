import { format } from 'date-fns';
import { useQuery } from 'react-query';

import { homeCalendar, homeSuperior } from '@/api/home';
import { paymentTypeState } from '@/store/modules/user';
import { IHomeCalendarResponae } from '@/types/home';
import { useAppDispatch, useAppSelector } from './useReduxHooks';
import { useEffect, useState } from 'react';

export const useHomeData = () => {
  const [enabled, setEnabled] = useState(false);
  const dispatch = useAppDispatch();
  const { filter } = useAppSelector((state) => state.refund);
  const { hospitals } = useAppSelector((state) => state.user);

  const params = {
    startDate: format(filter.startDate, 'yyyy-MM-dd'),
    endDate: format(filter.endDate, 'yyyy-MM-dd'),
    productType: filter.productType.name,
    subHospitalId: filter.hospitals.hospitalId,
  };

  useEffect(() => {
    setEnabled(true);
    return () => setEnabled(false);
  }, [filter]);

  const { refetch, data, isLoading } = useQuery<IHomeCalendarResponae, any>(
    ['home', filter],
    () => (hospitals.length > 0 ? homeSuperior(params) : homeCalendar(params)),
    {
      retry: false,
      enabled,
      onSuccess: (res) => {
        dispatch(paymentTypeState(res.paymentType === 'ORIGINAL'));
      },
      onError: (error) => {
        console.log('error', error);
      },
    },
  );

  return { refetch, data, isLoading };
};
