import React from 'react';
import styled from 'styled-components';

import logoImage from '@/assets/common/logoColor.png';
function NotPage() {
  return (
    <Container>
      <Header>
        <Logo src={logoImage} />
        <Title>의료용역 환급 서비스</Title>
      </Header>
      <Content>
        해당 서비스는 모바일 접속을 지원하지 않습니다.
        <br />
        PC로 접속바랍니다.
      </Content>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 45px;
  width: 100vw;
  height: 100vh;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const Logo = styled.img`
  width: 200px;
  height: 55px;
`;

const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  line-height: 38px;
  font-weight: bold;
`;

const Content = styled.p`
  font-size: ${(props) => props.theme.fontSize.regular};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayColors.scale2};
  text-align: center;
`;

export default NotPage;
