import { format } from 'date-fns';
import { ko } from 'date-fns/esm/locale';
import { useState } from 'react';
import DatePicker from 'react-datepicker';
import styled, { css } from 'styled-components';

import videoGif from '@/assets/kiosk/upload_video.gif';
import '@/styles/calendar.css';
import useUploadKioskVideo from '@/hooks/useUploadKioskVideo';
import { useAppSelector } from '@/hooks/useReduxHooks';

type Props = {
  file?: {
    name: string;
    size: number;
  };
  closeModal: () => void;
  isReservation: boolean;
};
function UploadModal({ file, closeModal, isReservation }: Props) {
  const [reservationDate, setReservationDate] = useState(new Date());
  const [isError, setIsError] = useState(false);
  const { mutate, isLoading } = useUploadKioskVideo({ closeModal, setIsError });
  const { isKioskStore } = useAppSelector((state) => state.user);

  const onChangeDate = (value: any) => {
    setReservationDate(value);
  };
  const onUpload = () => {
    const formData = new FormData();
    formData.append('uploadDate', format(reservationDate, 'yyyy-MM-dd'));
    formData.append('videoFile', file as any);
    formData.append('type', 'HOSPITAL');
    const payload = {
      kioskId: isKioskStore,
      formData,
    };
    mutate(payload);
  };
  return (
    <BackDrop>
      {isLoading ? (
        <LoadingContainer>
          <Gif src={videoGif} />
          <LoadingTitle>동영상 업로드 중입니다.</LoadingTitle>
          <Content>
            용량에 따라 최대 5분까지 소요될 수 있습니다. 잠시만 기다려주세요.
          </Content>
        </LoadingContainer>
      ) : isError ? (
        <Container>
          <Title>예약일 중복</Title>
          <Content>
            이미 해당 날짜에 게시 예약된 영상이 존재합니다.
            <br />
            다른 예약일을 선택하시거나 이전 영상을 삭제한 후 다시 시도해주세요.
          </Content>
          <ButtonWrapper>
            <Button colored onClick={closeModal}>
              확인
            </Button>
          </ButtonWrapper>
        </Container>
      ) : (
        <Container>
          <Title>{isReservation ? '예약' : '동영상'} 업로드 </Title>
          <Content>
            {isReservation ? '예약한 날짜부터 ' : ''}선택하신 영상을
            키오스크에서 재생합니다.
          </Content>
          {isReservation && (
            <DatePicker
              customInput={
                <ReservationBox>
                  예약일
                  <DateInput
                    readOnly
                    placeholder='YYYY.MM.DD'
                    value={format(reservationDate, 'yyyy.MM.dd')}
                  />
                </ReservationBox>
              }
              onChange={onChangeDate}
              locale={ko}
              minDate={new Date()}
            />
          )}
          <FileName>{file?.name}</FileName>
          <ButtonWrapper>
            <Button onClick={closeModal}>취소</Button>
            <Button colored onClick={onUpload}>
              업로드
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </BackDrop>
  );
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 270px;
  padding: 36px 40px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;
const LoadingContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;
const Gif = styled.img`
  width: 88px;
  height: 88px;
  margin-bottom: 16px;
`;
const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
`;
const LoadingTitle = styled(Title)`
  margin-bottom: 8px;
`;
const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;
const FileName = styled.div`
  color: #5f6165;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  background-color: #f5f6f7;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
`;
const ReservationBox = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
  display: flex;
  align-items: center;
  margin-top: 8px;
`;
const DateInput = styled.input`
  outline: none;
  border: none;
  border-bottom: 1px solid #246cf6;
  margin-left: 4px;
  color: #246cf6;
  width: 70px;
  padding: 0;
  height: 16px;
  cursor: pointer;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
  margin-top: 16px;
`;
const Button = styled.button<{ colored?: boolean }>`
  ${(props) =>
    props.colored
      ? css`
          background-color: #246cf6;
          color: #ffffff;
        `
      : css`
          background-color: #e5e6e8;
          color: #3a3b3e;
        `}
  font-size: 14px;
  line-height: 150%;
  padding: 15px 42px;
  font-weight: 600;
  border-radius: 6px;
  flex: 1;
  :hover {
    opacity: 0.6;
  }
`;
export default UploadModal;
