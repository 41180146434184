import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import leftArrowIcon from '@/assets/common/leftArrow.png';
import Text from '../common/Text';
import { useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { AxiosError } from 'axios';
import { passwordChange } from '@/api/user';

interface IProps {
  setIsPasswordChange: Dispatch<SetStateAction<boolean>>;
}

export interface IPasswordChange {
  newPassword: string;
}

function PasswordChange({ setIsPasswordChange }: IProps) {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      currentPassword: '',
      newPassword: '',
      newConfirmPassword: '',
    },
  });

  const { mutate: passwordChangeMutation } = useMutation<
    boolean,
    AxiosError<any>,
    IPasswordChange
  >((payload) => passwordChange(payload), {
    retry: false,
    onSuccess: async () => {
      setIsPasswordChange(false);
      alert('비밀번호 변경이 되었습니다.');
    },
    onError: (error: any) => {
      console.log('에러', error);
    },
  });

  const onSubmit = ({ newPassword }: IPasswordChange) => {
    const payload = {
      newPassword,
    };
    passwordChangeMutation(payload);
  };

  return (
    <Container>
      <Header>
        <Icon
          src={leftArrowIcon}
          width='24px'
          height='24px'
          alt='화살표.'
          onClick={() => setIsPasswordChange(false)}
        />
        <Title>비밀번호 변경</Title>
      </Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <InputContainer>
          <Text text='새 비밀번호' fontWeight='500' />
          <Input
            type='password'
            {...register('newPassword', {
              required: '필수 입력 사항입니다.',
            })}
            placeholder='비밀번호 입력'
          />
          {errors.newPassword && (
            <ErrorText>{errors.newPassword.message!}</ErrorText>
          )}
        </InputContainer>
        <InputContainer>
          <Text text='새 비밀번호 확인' fontWeight='500' />
          <Input
            type='password'
            {...register('newConfirmPassword', {
              required: '필수 입력 사항입니다.',
              validate: {
                matchesPassword: (value) => {
                  const { newPassword } = getValues();
                  return (
                    newPassword === value || '비밀번호가 일치하지 않습니다.'
                  );
                },
              },
            })}
            placeholder='비밀번호 확인'
          />
          {errors.newConfirmPassword && (
            <ErrorText>{errors.newConfirmPassword.message!}</ErrorText>
          )}
        </InputContainer>
        <DivisionLine />
        <ButtonWrapper>
          <Button
            type='button'
            isCancle
            onClick={() => setIsPasswordChange(false)}
          >
            취소
          </Button>
          <Button type='submit'>확인</Button>
        </ButtonWrapper>
      </Form>
    </Container>
  );
}

const Container = styled.div`
  padding: 22px 40px 0px 40px;
  height: 100%;
`;

const Header = styled.div`
  display: flex;
  gap: 12px;
`;

const Icon = styled.img`
  object-fit: contain;
  cursor: pointer;
`;

const Title = styled.p`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const Form = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
  margin-top: 15px;
  padding-top: 135px;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 6px;
  position: relative;
  width: 100%;
  max-width: 600px;
`;

const Input = styled.input`
  padding: 13px 10px;
  border-radius: 4px;
  width: 480px;
  color: ${(props) => props.theme.grayColors.scale2};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
`;

const DivisionLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 60px;
  background-color: ${(props) => props.theme.grayColors.scale3};
`;

const ButtonWrapper = styled.div`
  display: flex;
  gap: 12px;
  margin-top: 24px;
`;

const Button = styled.button<{ isCancle?: boolean }>`
  width: 100px;
  height: 42px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border-radius: 2px;
  border: ${(props) =>
    props.isCancle ? `2px solid ${props.theme.priamryColors.primary}` : 'none'};
  color: ${(props) =>
    props.isCancle
      ? props.theme.priamryColors.primary
      : props.theme.pointColors.white};
  background-color: ${(props) =>
    props.isCancle
      ? props.theme.pointColors.white
      : props.theme.priamryColors.primary};
`;

const ErrorText = styled.p`
  position: absolute;
  bottom: -25px;
  left: 125px;
  color: ${(props) => props.theme.priamryColors.error};
`;

export default PasswordChange;
