import React from 'react';
import { ThemeProvider } from 'styled-components';

import RouterContainer from '@/router';
import { GlobalStyle, theme } from './style';
import { QueryClient, QueryClientProvider } from 'react-query';

function App() {
  const queryClient = new QueryClient();

  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <RouterContainer />
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
