import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { deleteAllVideos } from '@/api/kiosk';
import { DeleteAllVideoPayload } from '@/types/kiosk';

function useDeleteAllKioskVideos() {
  const queryClient = useQueryClient();

  return useMutation<null, AxiosError, DeleteAllVideoPayload>(
    (payload) => deleteAllVideos(payload),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('kioskVideoList');
      },
      onError: () => {
        alert('서버와의 요청이 원활하지 않습니다. 다시 시도해주세요.');
      },
    }
  );
}

export default useDeleteAllKioskVideos;
