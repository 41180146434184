import styled from 'styled-components';
import ICON_ALERT from '@/assets/common/error.png';
import FlexWrap from '@/components/common/FlexWrap';

type Props = {
	expiryDate?: string;
};

function WarningExpired({ expiryDate }: Props) {
	return (
		<ToastMessage>
			<FlexWrap alignItems='center' gap='12px'>
				<Image src={ICON_ALERT} />
				<ToastText>
					{expiryDate} 에 유치기관 등록이 만료됩니다.
					<SubText>
						서비스 이용 유지를 위해 유치기관 만료일 갱신 후 KTP
						고객센터(02-6275-8011)로 갱신 여부를 알려주세요.
					</SubText>
				</ToastText>
			</FlexWrap>
			<LinkBtn
				href='https://www.medicalkorea.or.kr/korp/main.do'
				target='_blank'
			>
				갱신하기
			</LinkBtn>
		</ToastMessage>
	);
}

export default WarningExpired;

const ToastMessage = styled.div`
	width: 100%;
	background-color: #d3e2fd;
	z-index: 100;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 8px 40px;
	font-weight: bold;
	font-size: 16px;
`;

const ToastText = styled.p`
	color: #ed0828;
	margin-top: 2px;
`;

const SubText = styled.p`
	font-size: 16px;
	font-weight: 500;
	letter-spacing: -0.32px;
`;

const Image = styled.img`
	width: 20px;
	height: 20px;
`;

const LinkBtn = styled.a`
	background-color: #1856cd;
	border-radius: 8px;
	padding: 8px 16px;
	color: white;
	:hover {
		opacity: 0.7;
	}
`;
