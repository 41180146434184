import React, { useEffect, useRef, useState } from 'react';
import styled, { css } from 'styled-components';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { setDirectPaymnet } from '@/store/modules/refund';
import { getComma } from '@/util/format';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useForm, useFormContext } from 'react-hook-form';
import Typography from '../common/Typography';
import FlexWrap from '../common/FlexWrap';
import noticeIcon from '@/assets/refund/notice.png';
import Icon from '../common/Icon';

function AmountDetail({ isPayment, setLeafletModalInfo }: any) {
  const [totalPayPrice, setTotalPayPrice] = useState(0);
  const [paymentCard, setPaymentCard] = useState('0');
  const [paymentCash, setPaymentCash] = useState('0');
  const [refundCorp, setRefundCorp] = useState('유치기관');
  const isViewModalRef = useRef(false);

  const { isAutoPayment, isKioskStore, hospitalType } = useAppSelector(
    (state) => state.user
  );
  const { isDirectPayment, isDirectPassport } = useAppSelector(
    (state) => state.refund
  );
  let paymentCardRef = useRef<string | number>(0);
  let paymentCashRef = useRef<string | number>(0);
  const methods = useFormContext();
  const { register, setValue } = useForm();
  const { temporaryStorage } = useAppSelector((state) => state.refund);
  const { isKioskRefundModal } = useAppSelector((state) => state.validate);

  const dispatch = useAppDispatch();

  // 임시저장
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      let newTotalPayPrice = 0;
      temporaryStorage.supply.forEach((item: any) => {
        newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
      });
      setTotalPayPrice(newTotalPayPrice);
      setPaymentCash(temporaryStorage.paymentCash || '0');
      setPaymentCard(temporaryStorage.paymentCard || '0');
      setValue('paymentCash', temporaryStorage.paymentCash || '0');
      setValue('paymentCard', temporaryStorage.paymentCard || '0');
    }
  }, [temporaryStorage]);

  const supplyData = [...methods.getValues('supply')];

  // 금액 계산
  useEffect(() => {
    let newTotalPayPrice = 0;
    methods.getValues('supply').forEach((item: any) => {
      newTotalPayPrice += Number(String(item.price).replace(/(,)/g, ''));
    });
    setTotalPayPrice(newTotalPayPrice);
    if (newTotalPayPrice <= 5500000) {
      isViewModalRef.current = false;
    }

    if (
      (!isAutoPayment && !isKioskStore) ||
      isDirectPassport ||
      isViewModalRef.current
    ) {
      return;
    }

    if (newTotalPayPrice > 5500000) {
      dispatch(setDirectPaymnet(true));
      setLeafletModalInfo({
        active: true,
        isOver550: true,
        isKioskError: false,
      });
      isViewModalRef.current = true;
    } else {
      isDirectPayment && dispatch(setDirectPaymnet(false));
    }
  }, [supplyData, isDirectPayment]);

  const paymentTypeCheck = () => {
    return isAutoPayment && !isDirectPayment && !isDirectPassport
      ? totalPayPrice - getRefundAmount(totalPayPrice)
      : totalPayPrice;
  };

  useEffect(() => {
    // 초기화 현금
    if (totalPayPrice <= 0) {
      setPaymentCash('0');
      setPaymentCard('0');
      paymentCashRef.current = '0';
      paymentCardRef.current = '0';
      methods.setValue('paymentCash', paymentCashRef.current);
      methods.setValue('paymentCard', paymentCardRef.current);
      return;
    }

    const newCardValue = getComma(
      paymentTypeCheck() - +paymentCash.replace(/(,)/g, '')
    );

    // 카드금액이 -가 됬을때
    if (+newCardValue.replace(/(,)/g, '') < 0) {
      let cashValue =
        isAutoPayment && !isDirectPayment && !isDirectPassport
          ? totalPayPrice - getRefundAmount(totalPayPrice)
          : totalPayPrice;
      setPaymentCard('0');
      setPaymentCash(getComma(cashValue));
      paymentCardRef.current = '0';
      paymentCashRef.current = getComma(cashValue);
      methods.setValue('paymentCash', paymentCashRef.current);
      methods.setValue('paymentCard', paymentCardRef.current);
      return;
    }

    setPaymentCard(newCardValue);
    paymentCardRef.current = newCardValue;

    if (methods) {
      methods.setValue('paymentCard', paymentCardRef.current);
    }
  }, [totalPayPrice, isDirectPayment]);

  const onChangePrice = (e: any) => {
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      e.target.value.charAt(0) === '0'
        ? e.target.value.substring(1)
        : e.target.value;

    let cashValue = newtarget.replace(/(,)/g, '');
    let cardValue = +paymentTypeCheck() - +cashValue;

    if (cashValue >= totalPayPrice) {
      cashValue =
        isAutoPayment && !isDirectPayment && !isDirectPassport
          ? totalPayPrice - getRefundAmount(totalPayPrice)
          : totalPayPrice;
      cardValue = 0;
    }

    setPaymentCard(getComma(cardValue));
    setPaymentCash(getComma(cashValue));

    paymentCardRef.current = getComma(cardValue);
    paymentCashRef.current = getComma(cashValue);

    methods.setValue('paymentCard', paymentCardRef.current);
    methods.setValue('paymentCash', paymentCashRef.current);
  };

  const onChangeRefundCorp = (way: string) => {
    methods.setValue('refundCorp', way);
    setRefundCorp(way);
  };

  useEffect(() => {
    setPaymentCard(methods.getValues('paymentCard'));
    setPaymentCash(methods.getValues('paymentCash'));
  }, [isKioskRefundModal]);

  return (
    <>
      <Typography size='20px' fontWeight='500' lineHeight='28px'>
        금액 상세
      </Typography>
      {hospitalType === 'EXTRA_HOSTING_CORP' && (
        <RefundMethodContainer gap='16px'>
          <RefundMethodTitle>
            <Typography size='16px' fontWeight='500' lineHeight='24px'>
              고객 유형
            </Typography>
          </RefundMethodTitle>
          <RefundMethod onClick={() => onChangeRefundCorp('유치기관')}>
            <StyledRadio
              type='radio'
              value='유치기관'
              checked={refundCorp === '유치기관'}
            />
            <Typography size='16px' lineHeight='24px' fontWeight='400'>
              유치기관
            </Typography>
          </RefundMethod>
          <RefundMethod onClick={() => onChangeRefundCorp('개인')}>
            <StyledRadio
              type='radio'
              value='KTP'
              checked={refundCorp === '개인'}
            />
            <Typography size='16px' lineHeight='24px' fontWeight='400'>
              개인
            </Typography>
          </RefundMethod>
        </RefundMethodContainer>
      )}
      <Container isPayment={isPayment} isFullPayment={!isAutoPayment}>
        <SumContainer>
          {isAutoPayment && !isDirectPayment && !isDirectPassport ? (
            <>
              <Sum>
                <SumTitle>의료금액</SumTitle>
                <SumAmount>{getComma(totalPayPrice)} 원</SumAmount>
              </Sum>
              <Sum>
                <SumTitle>환급액</SumTitle>
                <SumAmount>
                  {getComma(getRefundAmount(totalPayPrice))} 원
                </SumAmount>
              </Sum>
              <Sum>
                <SumTitle>최종 결제금액</SumTitle>
                <SumAmount>
                  {getComma(totalPayPrice - getRefundAmount(totalPayPrice))}원
                </SumAmount>
              </Sum>
            </>
          ) : (
            <>
              <Sum isFullPayment>
                <SumTitle isFullPayment>결제금액</SumTitle>
                <SumAmount
                  isFullPayment
                  isColorRed={isDirectPayment && Boolean(isAutoPayment)}
                >
                  {getComma(totalPayPrice)} 원
                </SumAmount>
              </Sum>
              <Sum isFullPayment>
                <SumTitle isFullPayment>예상환급액</SumTitle>
                <SumAmount isFullPayment>
                  {getComma(getRefundAmount(totalPayPrice))} 원
                </SumAmount>
              </Sum>
              {isAutoPayment && isDirectPayment && (
                <Notice>
                  <Icon imgUrl={noticeIcon} width='16px' height='16px' />
                  <Typography color='#fff'>
                    환급 규정에 따라 일부 특정국가 고객 및 금액에 따라 부가세
                    포함 된 금액으로 결제액을 받습니다.
                  </Typography>
                </Notice>
              )}
            </>
          )}
        </SumContainer>
        <PaymentContainer isFullPayment={!isAutoPayment}>
          <Payment isFullPayment>
            <PaymentMethod>카드 결제</PaymentMethod>
            <PaymentPrice
              readOnly
              {...register('paymentCard')}
              type='text'
              value={paymentCard}
            />
          </Payment>
          <Payment>
            <PaymentMethod>현금 결제</PaymentMethod>
            <PaymentPrice
              {...register('paymentCash')}
              type='text'
              onChange={onChangePrice}
              value={paymentCash}
              placeholder='현금 결제금액 입력'
              disabled={Boolean(!totalPayPrice)}
            />
          </Payment>
        </PaymentContainer>
      </Container>
    </>
  );
}

const Container = styled.div<{ isPayment?: boolean; isFullPayment?: boolean }>`
  display: flex;
  gap: 40px;
  width: 100%;
  @media ${(props) => props.theme.laptop} {
    ${(props) =>
      !props.isFullPayment &&
      css`
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
      `}
  }
`;

const SumContainer = styled.div`
  position: relative;
  width: 1130px;
  display: flex;
  flex: 3;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
  @media ${(props) => props.theme.laptop} {
    width: 100%;
  }
`;

const Sum = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  flex-direction: ${(props) => (props.isFullPayment ? 'row' : 'column')};
  align-items: center;
  justify-content: space-between;
  flex: 1;
  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

const SumTitle = styled.h3<{ isFullPayment?: boolean }>`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
  text-align: center;
  background-color: #d7dfe4;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.large};

  ${(props) =>
    props.isFullPayment &&
    css`
      width: auto;
      padding: 12px 28px;
    `}
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
  }
`;

const SumAmount = styled.span<{
  isRefund?: boolean;
  isFullPayment?: boolean;
  isColorRed?: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px 0;
  /* width: 100%; */
  color: ${(props) => props.isRefund && props.theme.priamryColors.primary};
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  color: ${(props) => props.isColorRed && 'red'};
  ${(props) =>
    props.isFullPayment &&
    css`
      padding: 10px 15px 10px 0;
    `}
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
  }
`;

const PaymentContainer = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  /* flex-direction: ${(props) => (props.isFullPayment ? 'row' : 'column')}; */
  align-items: center;
  justify-content: center;
  /* border: 1px solid ${(props) => props.theme.grayColors.scale3}; */
`;

const Payment = styled.div<{ isFullPayment?: boolean }>`
  display: flex;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-bottom: none;
  }
  ${(props) =>
    props.isFullPayment &&
    css`
      :first-child {
        border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
        border-right: none;
      }
    `}
`;

const PaymentMethod = styled.p`
  padding: 12px 35px;
  background-color: #d7dfe4;
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.large};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
  }
`;

const PaymentPrice = styled.input`
  border: none;
  flex: 1;
  padding: 10px 20px;
  text-align: right;
  font-size: ${(props) => props.theme.fontSize.medium};
  ::placeholder {
    color: ${(props) => props.theme.grayColors.scale3};
  }
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
    z-index: 1;
  }
`;
const RefundMethodContainer = styled(FlexWrap)`
  align-items: center;
  border: 1px solid #cbccce;
  background-color: #fff;
`;
const RefundMethod = styled(FlexWrap)`
  cursor: pointer;
  gap: 8px;
`;
const RefundMethodTitle = styled(FlexWrap)`
  height: 100%;
  align-items: center;
  padding: 12px 100px;
  background-color: #d7dfe4;
`;
const StyledRadio = styled.input`
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  border: 4px solid #cbccce;
  cursor: pointer;
  &::disabled {
    cursor: default;
  }
  &:checked {
    border: 4px solid #354eff;
    background-color: #ffffff;
  }
`;
const Notice = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  position: absolute;
  bottom: -65px;
  width: 100%;
  background-color: #ff2d55;
  padding: 8px 13px;
  border-radius: 4px;
  @media ${(props) => props.theme.laptop} {
    bottom: -200px;
  }
`;

export default AmountDetail;
