import React, { Dispatch, SetStateAction } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { NoticeHomeHeader } from '@/constants/refundGraph';
import { Notice } from '@/types/home';
import { formatDotDate } from '@/util/format';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';
import Paging from '../common/Paging';
import Text from '../common/Text';
import { newRegisterHospital } from '@/util/common';

interface IProps {
  totalPage?: number;
  setPageNumber?: Dispatch<SetStateAction<number>>;
  medicalNotice: Notice[];
  isNotice?: boolean;
  setIsNotice?: Dispatch<SetStateAction<boolean>>;
}

function HomeNotice({
  medicalNotice,
  isNotice,
  totalPage,
  setPageNumber,
}: IProps) {
  const navigate = useNavigate();

  // const newArray = medicalNotice;
  // medicalNotice.forEach((item, index) => {
  //   let tmp = newArray[index];
  //   if (item.fixed) {
  //     newArray.splice(index, 1);
  //     newArray.unshift(tmp);
  //   }
  // });
  // medicalNotice = newArray;

  return (
    <>
      <Container dir='column'>
        {!isNotice && (
          <TitleContainer justifyContent='space-between' alignItems='center'>
            <Title>주요 공지사항</Title>
            <Button
              text='+ 더보기'
              color='#757575'
              fontWeight
              onClick={() => navigate('/notice')}
            />
          </TitleContainer>
        )}

        <Header>
          {NoticeHomeHeader.map((item) => (
            <HeaderTitle key={item.id} flex={item.flex}>
              {item.title}
            </HeaderTitle>
          ))}
        </Header>

        <NoticeList>
          {medicalNotice.map((item, index) => (
            <NoticeItem
              key={index}
              to={'/notice/detail'}
              state={{ isDetail: true, noticeIndex: item.noticeIndex }}
            >
              <Content
                flex={1}
                isNewNotice={newRegisterHospital(item.scheduledDate)}
              >
                {newRegisterHospital(item.scheduledDate)
                  ? 'NEW'
                  : '0' + Number(index + 1)}
              </Content>
              <Content flex={12} isFlexStart>
                <Text text={item.title} />
              </Content>
              <Content flex={1.5}>{formatDotDate(item.scheduledDate)}</Content>
              <Content flex={1.5}>{item.writer}</Content>
            </NoticeItem>
          ))}
        </NoticeList>
      </Container>
      {totalPage && setPageNumber && (
        <Paging setPageNumber={setPageNumber} totalPage={totalPage} />
      )}
    </>
  );
}

const Container = styled(FlexWrap)`
  position: relative;
  margin-top: 28px;
  @media ${(props) => props.theme.laptop} {
    margin-top: 0px;
  }
`;

const TitleContainer = styled(FlexWrap)`
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  background-color: #eaeff3;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const HeaderTitle = styled.p<{ flex?: number }>`
  padding: 12px 26px;
  flex: ${(props) => props.flex};
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  :not(:last-child) {
    border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
  @media ${(props) => props.theme.laptop} {
    padding: 9px 26px;
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
  }
`;

const NoticeList = styled.ul`
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const NoticeItem = styled(Link)`
  display: flex;
  align-items: center;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Content = styled.div<{
  flex: number;
  isFlexStart?: boolean;
  isNewNotice?: boolean;
}>`
  display: flex;
  justify-content: ${(props) => (props.isFlexStart ? 'flex-start' : 'center')};
  flex: ${(props) => props.flex};
  padding: 12px 26px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  color: ${(props) => props.isNewNotice && '#f44336'};

  @media ${(props) => props.theme.laptop} {
    padding: 9px 26px;
  }
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
  p {
    :hover {
      opacity: 0.8;
    }
  }
`;

export default HomeNotice;
