import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { IRefundDetailContent } from '@/types/refund';
import { getComma } from '@/util/format';
import Checkbox from '../common/CheckBox';
import Button from '../common/Button';
import DetailModal from './DetailModal';
import { IModal } from '../home/RecentRefund';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { refundStatusDivision } from '@/util/common';
import Typography from '../common/Typography';

interface IProps {
  refund: IRefundDetailContent;
  idx: number;
  removeArray: number[];
  currentPage: number;
  setRemoveArray: Dispatch<SetStateAction<number[]>>;
}

function RefundDetailItem({
  refund,
  idx,
  removeArray,
  setRemoveArray,
}: IProps) {
  const [isModal, setIsModal] = useState<any>({ isActive: false });
  const [isCheck, setIsCheck] = useState(false);
  const [detailModal, setDetailModal] = useState<IModal>({
    isActive: false,
  });

  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);

  const onClick = () => {
    const newArray = removeArray;

    if (isCheck) {
      newArray.forEach((arr, i) => {
        if (arr === refund.refundIndex) {
          newArray.splice(i, 1);
        }
      });
    } else {
      newArray.push(refund.refundIndex);
    }
    setRemoveArray(newArray);
    setIsCheck((prev) => !prev);
  };

  return (
    <Item>
      <Content flex={0.7}>{idx + 1}</Content>
      {hospitals.length > 0 && (
        <Content flex={1.2}>{refund.hospitalName}</Content>
      )}
      <Content flex={1.8}>
        {refundStatusDivision(refund.refundHistoryStatus)}
      </Content>
      <Content flex={2}>{refund.supplyDate}</Content>
      <Content flex={1.5}>{refund.nation}</Content>
      <Content flex={3.5}>{refund.touristName}</Content>
      {isAutoPayment ? (
        <>
          <Content flex={3}>{getComma(refund.totalPrice)} 원</Content>
          <Content flex={2}>{getComma(refund.refund)} 원</Content>
          <Content flex={3}>
            {getComma(
              refund.paymentType === 'ORIGINAL'
                ? +refund.totalPrice - +refund.refund
                : +refund.totalPrice
            )}{' '}
            원
          </Content>
        </>
      ) : (
        <>
          <Content flex={3}>{getComma(refund.totalPrice)} 원</Content>
          <Content flex={2}>{getComma(refund.refund)} 원</Content>
        </>
      )}
      <Content flex={2.2}>
        <Button
          text='상세보기'
          padding='5px 10px'
          color='#0b42ad'
          border='2px solid #0b42ad'
          borderRadius='4px'
          fontWeight
          onClick={() => setDetailModal({ data: refund, isActive: true })}
        />
      </Content>
      <Content flex={2}>
        <Checkbox
          isCheck={isCheck}
          onClick={onClick}
          isDisable={
            refund.refundHistoryStatus === 'CANCEL' ||
            refund.isTransfer ||
            (refund.canCancel && !refund.canCancel)
          }
        />
        {refund.isTransfer && (
          <RefundStatus>
            <Typography size='10px' color='#BDBDBD'>
              (환급완료)
            </Typography>
          </RefundStatus>
        )}
      </Content>
      {isModal.isActive && (
        <DetailModal refund={refund} setIsModal={setIsModal} />
      )}
      {detailModal.isActive && (
        <DetailModal
          refund={detailModal.data}
          setUploadModal={setDetailModal}
        />
      )}
    </Item>
  );
}

const Item = styled.li`
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};

  background-color: ${(props) => props.theme.pointColors.white};
`;

const Content = styled.div<{ flex: number }>`
  position: relative;
  display: flex;
  flex: ${(props) => props.flex};
  text-align: center;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;
const RefundStatus = styled.div`
  display: flex;
  justify-content: center;
  position: absolute;
  margin-left: 85px;
`;
export default RefundDetailItem;
