import { createSlice } from '@reduxjs/toolkit';

type ModalStoreType = {
  isActive: boolean;
  data: any;
  type: string | null;
};

const initialState: ModalStoreType = {
  isActive: false,
  data: null,
  type: null,
};

const modalSlice = createSlice({
  name: 'modal',
  initialState,
  reducers: {
    updateModal: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { updateModal } = modalSlice.actions;
export default modalSlice.reducer;
