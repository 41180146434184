import React, {
  Dispatch,
  MutableRefObject,
  RefObject,
  SetStateAction,
} from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import { birthMonthList } from '@/constants/refund';
import useValidate from '@/hooks/useValidate';
import ErrorMessage from '../common/ErrorMessage';
import Icon from '../common/Icon';
import Typography from '../common/Typography';
import MonthDropDownItem from './MonthDropDownItem';
import upArrowIcon from '@/assets/common/upArrow.png';
import downArrowIcon from '@/assets/common/downArrow.png';
import { ExpireDateType } from './ExpireDateModal';
import { useAppSelector } from '@/hooks/useReduxHooks';

type BirthDateType = {
  expireDate: ExpireDateType;
  setExpireDate: Dispatch<SetStateAction<ExpireDateType>>;
  isDropDownOpen: boolean;
  setIsDropDownOpen: Dispatch<SetStateAction<boolean>>;
  selectedItemIndex: number;
  setSelectedItemIndex: Dispatch<SetStateAction<number>>;
  inputValueRef: MutableRefObject<{ label: string; value: string }>;
  dropDownRef: RefObject<HTMLUListElement>;
};

function BirthDate({
  expireDate,
  setExpireDate,
  setIsDropDownOpen,
  inputValueRef,
  selectedItemIndex,
  isDropDownOpen,
  setSelectedItemIndex,
  dropDownRef,
}: BirthDateType) {
  const {
    register,
    formState: { errors },
    setFocus,
    control,
    setValue,
  } = useFormContext();

  const { validateNumericInput } = useValidate();
  const { isPassportCheck } = useAppSelector((state) => state.validate);

  return (
    <>
      <Label>생년월일</Label>
      <BirthContainer>
        {/*  Year */}
        <Birth flex={1}>
          <BirthInput
            {...register('birthYear', {
              required: true,
            })}
            maxLength={4}
            placeholder='YYYY'
            disabled={isPassportCheck}
            autoComplete='off'
            value={expireDate.year}
            onChange={(e) =>
              setExpireDate((prev) => ({
                ...prev,
                year: validateNumericInput(e.target.value, /[^1-2]/g),
              }))
            }
          />
          <Typography>년</Typography>
        </Birth>
        {/*  Month */}
        <Birth flex={1.2}>
          <Controller
            name='birthMonth'
            control={control}
            render={() => (
              <DropDownContainer
                tabIndex={0}
                disabled={isPassportCheck}
                onBlur={() => {
                  setIsDropDownOpen(false);
                }}
                onFocus={() => {
                  inputValueRef.current.value = '';
                  setIsDropDownOpen(true);
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' || e.key === ' ') {
                    e.preventDefault();
                    if (selectedItemIndex === -1) return;
                    const selectedItem = birthMonthList[selectedItemIndex];
                    setExpireDate((prev) => ({
                      ...prev,
                      month: selectedItem,
                    }));
                    setValue('birthMonth', selectedItem);
                    setIsDropDownOpen(!isDropDownOpen);
                    setFocus('birthDay');
                  } else if (e.key === 'ArrowUp') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) => Math.max(prev - 1, 0));
                  } else if (e.key === 'ArrowDown') {
                    e.preventDefault();
                    setSelectedItemIndex((prev) =>
                      Math.min(prev + 1, birthMonthList.length - 1)
                    );
                  } else {
                    inputValueRef.current.value += e.key;
                    birthMonthList.forEach((item) => {
                      if (inputValueRef.current.value === item.value) {
                        setExpireDate((prev) => ({
                          ...prev,
                          month: item,
                        }));
                        setIsDropDownOpen(!isDropDownOpen);
                        setFocus('birthDay');
                      }
                    });
                  }
                }}
              >
                <DropDownHeader
                  id='no_focus'
                  isValue={Boolean(expireDate.month.value)}
                  disabled={isPassportCheck}
                >
                  {expireDate.month.label || 'MM'}
                  <>
                    <Icon
                      width='12px'
                      height='12px'
                      imgUrl={isDropDownOpen ? upArrowIcon : downArrowIcon}
                    />
                  </>
                </DropDownHeader>
                {isDropDownOpen && (
                  <DropDownList ref={dropDownRef}>
                    {birthMonthList.map((item, index) => (
                      <MonthDropDownItem
                        key={index}
                        idx={index}
                        item={item}
                        setExpireDate={setExpireDate}
                        selectedItemIndex={selectedItemIndex}
                        setIsDropDownOpen={setIsDropDownOpen}
                      />
                    ))}
                  </DropDownList>
                )}
              </DropDownContainer>
            )}
          />
          <Typography>월</Typography>
        </Birth>
        {/*  Day */}
        <Birth flex={1}>
          <BirthInput
            {...register('birthDay', {
              required: true,
            })}
            maxLength={2}
            placeholder='DD'
            disabled={isPassportCheck}
            autoComplete='off'
            value={expireDate.day}
            onChange={(e) =>
              setExpireDate((prev) => ({
                ...prev,
                day: validateNumericInput(e.target.value, /[^0-3]/g),
              }))
            }
          />
          <Typography>일</Typography>
        </Birth>
      </BirthContainer>
      {errors.birthYear && (
        <ErrorMessage text={String(errors.birthYear.message)} absolute />
      )}
    </>
  );
}

const Label = styled.label`
  font-weight: ${(props) => props.theme.fontWeight.medium};
`;

const BirthContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  width: 80%;
  height: 48px;
  margin-bottom: 5px;
`;

const Birth = styled.div<{ flex: number }>`
  display: flex;
  align-items: center;
  position: relative;
  height: 100%;
  flex: ${(props) => props.flex};
`;

const BirthInput = styled.input`
  width: 90%;
  height: 100%;
  padding-left: 10px;
  border-radius: 4px;
  margin-right: 3px;
  outline: none;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  color: #212121;
  :disabled {
    background-color: rgba(224, 224, 224, 1);
    color: gray;
  }
  ::placeholder {
    color: #cbccce;
  }
  :focus {
    border-color: #246cf6;
  }
`;

const DropDownContainer = styled.div<{ disabled: boolean }>`
  position: relative;
  width: 100%;
  margin-right: 3px;
  border: 2px solid #bdbdbd;
  border-radius: 4px;
  height: 48px;
  background-color: ${(props) => props.disabled && 'rgba(224, 224, 224, 1)'};
  :focus {
    border: 2px solid #246cf6;
  }
`;
const DropDownHeader = styled.div<{ isValue: boolean; disabled: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  width: 100%;
  height: 48px;
  color: ${(props) =>
    props.disabled ? 'gray' : props.isValue ? '#212121' : '#BDBDBD'};
  padding: 0 10px;
`;
const DropDownList = styled.ul`
  position: absolute;
  margin-top: 10px;
  width: 100%;
  max-height: 300px;
  border: 1px solid #cdccce;
  overflow: auto;
  z-index: 10;
  background-color: #fff;
  :focus {
    background-color: #246cf6;
  }
`;

export default BirthDate;
