import React, { Dispatch, SetStateAction } from 'react';
import Select from 'react-select';
import styled from 'styled-components';

import FlexWrap from '@/components/common/FlexWrap';

type ViewSelectorProps = {
  setSize: Dispatch<SetStateAction<number>>;
  setPageNumber: Dispatch<SetStateAction<number>>;
};

function ViewSelector({ setSize, setPageNumber }: ViewSelectorProps) {
  const options = [
    { label: '10개씩 보기', value: 10 },
    { label: '100개씩 보기', value: 100 },
    { label: '500개씩 보기', value: 500 },
  ];

  const onChange = (e: any) => {
    setSize(e.value);
    setPageNumber(0);
  };
  return (
    <ViewContainer>
      <Select
        isSearchable={false}
        //@ts-ignore
        defaultValue={options[0]}
        onChange={onChange}
        //@ts-ignore
        options={options}
        styles={{
          container: (baseStyles: any) => ({
            ...baseStyles,
            marginLeft: 'auto',
            padding: 3,
            backgroundColor: '#ffffff',
            border: '1px solid #BDBDBD',
            borderRadius: 4,
          }),
          control: () => ({
            marginLeft: 'auto',
            border: 'none',
            display: 'flex',
            height: 22,
          }),
          indicatorSeparator: () => ({
            width: 0,
          }),
        }}
      />
    </ViewContainer>
  );
}

const ViewContainer = styled(FlexWrap)`
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
`;

export default ViewSelector;
