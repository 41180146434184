import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import {
  RefundHomeFullPaymentHeader,
  RefundHomeHeader,
} from '@/constants/refundGraph';
import { IHomeCalendarResponae, LatestRefundInfo } from '@/types/home';
import { comma } from '@/util/format';
import Button from '../common/Button';
import FlexWrap from '../common/FlexWrap';
import DetailModal from '../refundDetail/DetailModal';
import { useAppSelector } from '@/hooks/useReduxHooks';
import { useDispatch } from 'react-redux';
import { reset } from '@/store/modules/refund';

interface IProps {
  medicalInfo: IHomeCalendarResponae;
}

export interface IModal {
  data?: { refundIndex?: number | string };
  isActive: boolean;
}

function RecentRefund({ medicalInfo }: IProps) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAutoPayment, hospitals } = useAppSelector((state) => state.user);
  const [detailModal, setDetailModal] = useState<IModal>({
    isActive: false,
  });
  const { latestRefundInfo } = medicalInfo;
  const onClick = (item: LatestRefundInfo) => {
    setDetailModal({ data: item, isActive: true });
  };

  return (
    <Container dir='column'>
      <TitleContainer justifyContent='space-between' alignItems='center'>
        <Title>최근 환급내역</Title>
        <Button
          text='+ 더보기'
          color='#757575'
          fontWeight
          onClick={() => {
            navigate('/refund-detail');
            dispatch(reset());
          }}
        />
      </TitleContainer>
      <Header>
        {(isAutoPayment ? RefundHomeHeader : RefundHomeFullPaymentHeader).map(
          (item) => (
            <Fragment key={item.id}>
              {hospitals.length === 0 && item.title === '구분' ? (
                <></>
              ) : (
                <HeaderTitle flex={item.flex}>{item.title}</HeaderTitle>
              )}
            </Fragment>
          )
        )}
      </Header>
      <RefundList>
        {!latestRefundInfo[0] ? (
          <span>환급내역이 없습니다.</span>
        ) : (
          latestRefundInfo.map((item, index) => (
            <RefundItem key={item.refundIndex}>
              <Content flex={1}>{'0' + Number(index + 1)}</Content>
              {hospitals.length > 0 && (
                <Content flex={1.5}>{item.hospitalName}</Content>
              )}
              <Content flex={2.5}>{item.supplyDate}</Content>
              <Content flex={2.5}>{item.nation}</Content>
              <Content flex={2.5}>{item.touristName}</Content>
              {isAutoPayment ? (
                <>
                  <Content flex={2.5}>{comma(item.totalPrice)} 원</Content>
                  <Content flex={2.5}>{comma(item.refund)} 원</Content>
                  <Content flex={2.5}>
                    {comma(
                      item.paymentType === 'ORIGINAL'
                        ? +item.totalPrice - +item.refund
                        : +item.totalPrice
                    )}{' '}
                    원
                  </Content>
                </>
              ) : (
                <>
                  <Content flex={2.5}>{comma(item.totalPrice)} 원</Content>
                  <Content flex={2.5}>{comma(item.refund)} 원</Content>
                </>
              )}
              <Content flex={2.5}>
                <Button
                  text='상세보기'
                  width='100px'
                  height='32px'
                  color='#0b42ad'
                  border='2px solid #0b42ad'
                  borderRadius='4px'
                  fontWeight
                  onClick={() => onClick(item)}
                />
              </Content>
            </RefundItem>
          ))
        )}
      </RefundList>
      {detailModal.isActive && (
        <DetailModal
          refund={detailModal.data}
          setUploadModal={setDetailModal}
        />
      )}
    </Container>
  );
}

const Container = styled(FlexWrap)`
  position: relative;
  margin-top: 28px;
  @media ${(props) => props.theme.laptop} {
    margin-top: 0px;
  }
`;

const TitleContainer = styled(FlexWrap)`
  width: 100%;
`;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  margin-top: 24px;
  background-color: #eaeff3;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
`;

const HeaderTitle = styled.p<{ flex?: number }>`
  padding: 12px 0;
  flex: ${(props) => props.flex};
  text-align: center;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  :not(:last-child) {
    border-right: 2px solid ${(props) => props.theme.grayColors.scale3};
  }
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.xSmall};
    line-height: ${(props) => props.theme.lineHeight.xSmall};
    padding: 9px 0;
  }
`;

const RefundList = styled.ul`
  width: 100%;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const RefundItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Content = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 48px;
  flex: ${(props) => props.flex};
  padding: 12px 0;
  border-right: 2px solid ${(props) => props.theme.grayColors.scale3};
  @media ${(props) => props.theme.laptop} {
    padding: 9px 0;
    text-align: center;
  }
  :first-child {
    border-left: 2px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

export default RecentRefund;
