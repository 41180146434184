import styled from 'styled-components';

function VideoTableHeader() {
  return (
    <Row>
      <Cell>No</Cell>
      <Cell flex='5' leftArrange>
        자료명
      </Cell>
      <Cell>게시일</Cell>
      <Cell>미리보기</Cell>
      <Cell>재생</Cell>
      <Cell>삭제</Cell>
    </Row>
  );
}

const Row = styled.div`
  width: 100%;
  display: flex;
  background-color: #e9f0fe;
`;
const Cell = styled.div<{ flex?: string; leftArrange?: boolean }>`
  display: flex;
  flex: ${(props) => props.flex || 1};
  align-items: center;
  padding: 0 20px;
  height: 48px;
  border-right: 1px solid #cbccce;
  border-bottom: 1px solid #cbccce;
  justify-content: ${(props) => (props.leftArrange ? 'flex-start' : 'center')};
`;
export default VideoTableHeader;
