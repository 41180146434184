import React, { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import passportImg from '@/assets/refund/passport.png';
import FlexWrap from '../common/FlexWrap';
import LoadingView from '../common/LoadingView';
import Typography from '../common/Typography';

type ValidateKorModalProps = {
  isLoading: boolean;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  createFormattedBirthDate: () => void;
  forceRefundMutation: any;
};

function ValidateKorModal({
  isLoading,
  createFormattedBirthDate,
  onChange,
  forceRefundMutation,
}: ValidateKorModalProps) {
  const { getValues, register } = useFormContext();
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const onClickRefund = () => {
    const newBirthDate = createFormattedBirthDate();
    const { touristName, nationality, passportNumber, supplyDate } =
      getValues();
    const payload = {
      supplyDate,
      name: touristName.toUpperCase(),
      nationality: nationality.toUpperCase(),
      birthDate: newBirthDate,
      passportNumber: passportNumber.toUpperCase(),
    };
    forceRefundMutation(payload);
  };

  return (
    <BackDrop>
      <Container>
        <Typography
          size='21px'
          fontWeight='700'
          lineHeight='25px'
          padding='20px'
        >
          환자 정보 확인
        </Typography>
        <DivideLine />
        <Content>
          <Typography size='16px' fontWeight='500' lineHeight='24px'>
            고객님의 여권 번호를 확인해주세요.
          </Typography>
          <ImgWrapper>
            <ImgContainer>
              <Image src={passportImg} width='652px' height='246px' />
              <InputContainer>
                <Typography
                  size='18px'
                  fontWeight='700'
                  lineHeight='24px'
                  color='#0054A2'
                >
                  여권 번호 Passport No.
                </Typography>
                <Input
                  {...register('passportNumber')}
                  type='text'
                  ref={inputRef}
                  value={getValues('passportNumber').toUpperCase()}
                  placeholder='여권 번호 Passport No.'
                  autoComplete='off'
                  autoCapitalize='characters'
                  onChange={(e) => onChange(e)}
                />
              </InputContainer>
            </ImgContainer>
          </ImgWrapper>
        </Content>
        <DivideLine />
        <FlexWrap
          alignItems='center'
          justifyContent='flex-end'
          padding='24px 20px'
        >
          <Button onClick={onClickRefund}>
            {isLoading ? <LoadingView /> : '확인'}
          </Button>
        </FlexWrap>
      </Container>
    </BackDrop>
  );
}

export default ValidateKorModal;

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 10;
`;
const Container = styled.div`
  width: 1030px;
  height: 576px;
  background-color: #fff;
  border-radius: 12px;
`;
const Content = styled.div`
  padding: 28px 20px;
`;
const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  background-color: #e5e6e8;
`;
const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;
const ImgContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 732px;
  height: 310px;
  margin-top: 28px;
  border: 2px solid #95b6c0;
  border-radius: 12px;
`;
const Image = styled.img`
  object-fit: contain;
`;
const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 118px;
  left: 212px;
  width: 400px;
  height: 70px;
  background-color: #fff;
`;

const Input = styled.input`
  width: 100%;
  height: 42px;
  padding-left: 10px;
  border-radius: 4px;
  margin-right: 3px;
  outline: none;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  color: #212121;
  font-size: 16px;
  :disabled {
    background-color: ${(props) => props.theme.grayColors.scale4};
    color: gray;
  }
  ::placeholder {
    color: #cbccce;
  }
  :focus {
    border-color: #246cf6;
  }
`;
const Button = styled.button`
  width: 100px;
  height: 42px;
  border-radius: 2px;
  color: #ffffff;
  background-color: #030303;
  margin-left: 5px;
`;
