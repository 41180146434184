import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { AxiosError } from 'axios';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';

import downArrowIcon from '@/assets/common/downArrow.png';
import upArrowIcon from '@/assets/common/upArrow.png';
import { useAppSelector } from '@/hooks/useReduxHooks';
import FlexWrap from '../common/FlexWrap';
import Text from '../common/Text';
import { formatDotDate } from '@/util/format';
import Button from '../common/Button';
import { INotice } from '@/types/notice';
import { noticeDetailInfo } from '@/api/notice';
import { noticeState } from '@/store/modules/notice';

function NoticeDetail() {
  const dispatch = useDispatch();
  const {
    state: { noticeIndex },
  } = useLocation();
  const navigate = useNavigate();
  const { currentContent, nextContent, previewContent } = useAppSelector(
    (state) => state.notice
  );

  const [currentIndex, setCurrentIndex] = useState(noticeIndex);

  const onMoveNext = () => {
    setCurrentIndex(nextContent.noticeIndex);
  };

  const onMovePreview = () => {
    setCurrentIndex(previewContent.noticeIndex);
  };

  const { refetch } = useQuery<INotice[], AxiosError>(
    'noticeDetail',
    () => noticeDetailInfo(currentIndex),
    {
      retry: false,
      enabled: false,
      onSuccess: (res) => {
        noticeStateSave(res);
      },
      onError: (error) => {
        console.log('error', error);
      },
    }
  );
  useEffect(() => {
    refetch();
  }, [currentIndex]);

  const noticeStateSave = (notices: INotice[]) => {
    let preview;
    let current;
    let next;

    // 이전 다음 있을때
    if (notices.length === 3) {
      preview = notices[0];
      current = notices[1];
      next = notices[2];
      // 다음글만 있을때
    } else if (currentIndex === notices[0].noticeIndex) {
      preview = '';
      current = notices[0];
      next = notices[1];
      // 이전글만 있을때
    } else {
      preview = notices[0];
      current = notices[1];
      next = '';
    }
    const payload = {
      preview,
      current,
      next,
    };
    dispatch(noticeState(payload));
  };

  return (
    <>
      <Container>
        <Title>{currentContent.title}</Title>
        <WriteContainer gap='16px'>
          <Write gap='9px'>
            <Text text='작성일 |' color='#757575' />
            <Text
              text={
                currentContent.scheduledDate
                  ? formatDotDate(currentContent.scheduledDate)
                  : ''
              }
              color='#757575'
            />
          </Write>
          <Write gap='9px'>
            <Text text='작성자 |' color='#757575' />
            <Text text={currentContent.writer} color='#757575' />
          </Write>
        </WriteContainer>
        <ContentContainer
          dangerouslySetInnerHTML={{ __html: currentContent.content }}
        ></ContentContainer>
        <MovePage>
          {nextContent && (
            <MoveItem onClick={onMoveNext}>
              <Image src={upArrowIcon} />
              <Text
                text='다음 글'
                fontSize='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#757575'
              />
              <Text
                text={nextContent.title}
                fontSize='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#757575'
                hoverColor='#00658C'
              />
            </MoveItem>
          )}
          {previewContent && (
            <MoveItem onClick={onMovePreview}>
              <Image src={downArrowIcon} />
              <Text
                text='이전 글'
                fontSize='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#757575'
              />
              <Text
                text={previewContent.title}
                fontSize='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#757575'
                hoverColor='#00658C'
              />
            </MoveItem>
          )}
        </MovePage>
      </Container>
      <ListButton>
        <Button
          text='목록'
          width='100px'
          height='48px'
          color='#FFFFFF'
          borderRadius='4px'
          backgroundColor='#00658C'
          onClick={() => navigate('/notice')}
        />
      </ListButton>
    </>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  min-height: 700px;
  padding: 35px;
  background-color: ${(props) => props.theme.pointColors.white};
  flex: 1;
`;

const Title = styled.h2`
  margin-bottom: 32px;
  font-size: ${(props) => props.theme.fontSize.large};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.large};
`;

const WriteContainer = styled(FlexWrap)`
  position: relative;
  ::after {
    position: absolute;
    left: 0;
    bottom: -16px;
    width: 100%;
    height: 1px;
    background-color: ${(props) => props.theme.grayColors.scale3};
    content: '';
  }
`;

const Write = styled(FlexWrap)``;

const ContentContainer = styled.div`
  flex: 1;
  padding: 46px 0px;
  height: 80%;
  overflow: auto;
`;

const MovePage = styled.div`
  background-color: ${(props) => props.theme.blueColors.scale2};
`;

const MoveItem = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  padding: 20px 40px;
  cursor: pointer;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale2};
`;

const Image = styled.img`
  width: 24px;
  height: 24px;
`;

const ListButton = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 35px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

export default NoticeDetail;
