import QuickGuideDirect from '@/assets/refundStatement/quick_guide_direct.pdf';
import QuickGuideOriginal from '@/assets/refundStatement/quick_guide_original.pdf';

export const RequiredDataHeaderTitle = [
  { title: 'No.', flex: 1 },
  { title: '자료명', flex: 15 },
  { title: '다운로드', flex: 2.5 },
];

export const RequiredDataGraphList = [
  {
    fileName: 'KTP 부가세신고 퀵 가이드_의료용역.pdf',
    file: QuickGuideOriginal,
  },
  { fileName: 'KTP 부가세신고 퀵 가이드_의료용역.pdf', file: QuickGuideDirect },
];

export const RefundVatDateHeaderTitle = [
  { title: 'No.', flex: 1 },
  { title: '자료명', flex: 15 },
  { title: '등록일', flex: 2.5 },
];
