import React from 'react';
import styled from 'styled-components';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';
import RefundDetailList from '@/components/refundDetail/RefundDetailList';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function RefundDetail() {
	const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

	return (
		<Container>
			{isExpiryWarning ? (
				<WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
			) : (
				<RenewalNotification />
			)}
			<ContentWrapper>
				<Title>환급 내역</Title>
				<RefundDetailList />
			</ContentWrapper>
			<Footer />
		</Container>
	);
}

export default RefundDetail;

const Container = styled.div`
	display: flex;
	flex-direction: column;
	min-width: 1200px;
	height: 100vh;
	flex: 1;
	overflow-y: scroll;
	background-color: ${(props) => props.theme.blueColors.scale3};
`;

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: 22px 40px 85px 40px;
`;

const Title = styled.h1`
	font-size: ${(props) => props.theme.fontSize.xLarge};
	font-weight: ${(props) => props.theme.fontWeight.bold};
	line-height: ${(props) => props.theme.lineHeight.xLarge};
	@media ${(props) => props.theme.laptop} {
		font-size: ${(props) => props.theme.fontSize.large};
		line-height: ${(props) => props.theme.lineHeight.large};
	}
`;
