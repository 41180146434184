import React, { Dispatch, SetStateAction, useState } from 'react';
import styled from 'styled-components';

import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';
import directLeafletImg from '@/assets/refund/leaflet.png';
import Button from '../common/Button';
import { useFormContext } from 'react-hook-form';

type DirectModalType = {
  isKioskStore: boolean;
  leafletModalInfo: {
    active: boolean;
    isOver550: boolean;
    isKioskError: boolean;
  };
  setLeafletModalInfo: Dispatch<
    SetStateAction<{
      active: boolean;
      isOver550: boolean;
      isKioskError: boolean;
    }>
  >;
};

function DirectModal({
  isKioskStore,
  leafletModalInfo,
  setLeafletModalInfo,
}: DirectModalType) {
  const { setFocus } = useFormContext();
  const onClickCheck = () => {
    setLeafletModalInfo((prev) => ({
      ...prev,
      active: false,
      isKioskError: false,
      isOver550: false,
    }));
    setFocus('totalPrice');
  };
  return (
    <>
      <BackDrop>
        <Container dir='column' alignItems='center'>
          <Typography
            size='21px'
            lineHeight='25.2px'
            fontWeight='700'
            center
            margin='0 0 12px'
          >
            {isKioskStore
              ? leafletModalInfo.isKioskError
                ? '키오스크 고장 상태입니다.'
                : '키오스크 환급 불가 고객입니다.'
              : '시술 금액 전체를 결제해주세요.(환급금 차감 X)'}
          </Typography>

          {isKioskStore ? (
            leafletModalInfo.isKioskError ? (
              <Typography
                size='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#3A3B3E'
                center
              >
                키오스크 상태를 확인해주시고
                <br />
                조치가 어려울 경우{' '}
                <Typography color='#E42939'>
                  환급 안내서를 환자에게 전달
                </Typography>
                해주세요.
              </Typography>
            ) : (
              <Typography
                size='16px'
                lineHeight='24px'
                fontWeight='500'
                color='#3A3B3E'
                center
              >
                {leafletModalInfo.isOver550
                  ? '550만원 초과 시술은 '
                  : '장기 체류 비자 보유 고객 및 재외국민은 '}
                <Typography color='#E42939'> 키오스크 환급이 불가능</Typography>
                합니다.
                <br />
                결제가 끝나면{' '}
                <Typography color='#E42939'>
                  환급 안내서를 환자에게 전달
                </Typography>
                해주세요.
              </Typography>
            )
          ) : (
            <Typography
              size='16px'
              lineHeight='24px'
              fontWeight='500'
              color='#3A3B3E'
              center
            >
              {leafletModalInfo.isOver550
                ? '550만원 초과 시술은 '
                : '장기 체류 비자 보유 고객 및 재외국민은 '}
              <Typography color='#E42939'>
                부가세 포함 된 금액으로 결제
              </Typography>
              합니다.
              <br />
              결제하신 다음{' '}
              <Typography color='#E42939'>
                환급 안내서를 환자에게 전달
              </Typography>
              해주세요.
            </Typography>
          )}
          <ImageWrapper dir='column' alignItems='center'>
            <Typography color='#80848A' margin='0 0 16px'>
              환급 안내서
            </Typography>
            <Image src={directLeafletImg} width='464px' height='386px' />
          </ImageWrapper>

          <Button
            width='280px'
            height='42px'
            text='확인했습니다'
            backgroundColor='#246CF6'
            borderRadius='8px'
            color='#FFF'
            onClick={onClickCheck}
          />
        </Container>
      </BackDrop>
    </>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 10;
`;
const Container = styled(FlexWrap)`
  width: 1030px;
  height: 660px;
  border-radius: 12px;
  background-color: #fff;
  padding: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px;
  }
`;
const ImageWrapper = styled(FlexWrap)`
  background-color: ${(props) => props.theme.mono[5]};
  width: 100%;
  padding: 16px;
  margin: 24px;
  border-radius: 16px;
  @media ${(props) => props.theme.largeView} {
    padding: 24px;
  }
`;
const Image = styled.img`
  object-fit: contain;
  align-self: center;
  height: 351px;
  width: 249px;
`;
export default DirectModal;
