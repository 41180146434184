import React from 'react';
import styled from 'styled-components';

import Text from './Text';
import cancleImage from '@/assets/common/cancle.png';
import Button from './Button';

interface IProps {
  title: string;
  content: string;
  onCancle?: () => void;
  onComfirm?: () => void;
}

function Modal({ title, content, onCancle, onComfirm }: IProps) {
  return (
    <Wrapper>
      <Container>
        <Header>
          <Text
            text={title}
            fontSize='24px'
            lineHeight='32px'
            fontWeight='700'
          />
          <Cancle src={cancleImage} onClick={onCancle} />
        </Header>
        <Text text={content} />
        <DivideLine />
        <ButtonWrapper>
          <Button
            text='아니요'
            color='#212121'
            width='100px'
            border='1px solid #212121'
            borderRadius='4px'
            padding='13px 15px'
            onClick={onCancle}
          />
          <Button
            text='네'
            color='#FFFFFF'
            backgroundColor='#212121'
            width='100px'
            border='1px solid #212121'
            borderRadius='4px'
            padding='13px 15px'
            onClick={onComfirm}
          />
        </ButtonWrapper>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 270px;
  padding: 36px 40px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Cancle = styled.img`
  top: 0;
  right: 0;
  width: 18px;
  height: 18px;
  cursor: pointer;
`;

const DivideLine = styled.div`
  width: 100%;
  height: 1px;
  margin-top: 10px;
  background-color: ${(props) => props.theme.grayColors.scale4};
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 10px;
`;

export default Modal;
