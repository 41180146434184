import React from 'react';
import styled from 'styled-components';

import logo from '@/assets/common/logo.png';
import FlexWrap from '../common/FlexWrap';
import { useNavigate } from 'react-router-dom';

type navigationType = {
  isTestServer: boolean;
};

function Naviagtion({ isTestServer }: navigationType) {
  const navigate = useNavigate();
  return (
    <Container isTestServer={isTestServer}>
      <FlexWrap gap='24px'>
        <Logo onClick={() => navigate('/')}>
          <Image src={logo} alt='로고.' />
        </Logo>
      </FlexWrap>
    </Container>
  );
}

const Container = styled.nav<{ isTestServer: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 24px;
  height: 80px;
  padding: 21px 40px;
  z-index: 10;
  background-color: ${(props) => (props.isTestServer ? 'green' : '#0b42ad')};
`;

const Logo = styled.div`
  cursor: pointer;
`;

const Image = styled.img`
  width: 114px;
  height: 38px;
`;

export default Naviagtion;
