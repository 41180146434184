import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import RefundPrintDetail from './RefundPrintDetail';

interface IProps {
  touristIndex: number;
  refundIndex: number;
  setIsPreview: Dispatch<SetStateAction<boolean>>;
}

function RefundPrint({ touristIndex, refundIndex, setIsPreview }: IProps) {
  return (
    <Container>
      <RefundPrintDetail
        touristIndex={touristIndex}
        refundIndex={refundIndex}
        setIsPreview={setIsPreview}
      />
    </Container>
  );
}

const Container = styled.div``;

export default RefundPrint;
