import React from 'react';
import styled from 'styled-components';
import FlexWrap from '../common/FlexWrap';
import Typography from '../common/Typography';

function RemoveModal({
  title,
  text,
  data,
  createdDate,
  setIsRemoveModal,
  onRemove,
}: any) {
  return (
    <BackDrop>
      <Container>
        <Typography
          size='21px'
          fontWeight='700'
          lineHeight='31.5px'
          color='#030303'
        >
          {title}
        </Typography>
        <Typography fontWeight='500' margin='20px 0 20px 0' color='#5F6165'>
          {text}
        </Typography>
        {createdDate && setIsRemoveModal && (
          <TemporaryInfo>
            <Typography>저장일 : {createdDate.slice(0, 10) || '-'}</Typography>
            <Typography>국적 : {data.nation || '-'}</Typography>
            <Typography>고객 성함 : {data.name || '-'}</Typography>
            <Typography>여권 번호 : {data.passportNumber || '-'}</Typography>
          </TemporaryInfo>
        )}
        <FlexWrap gap='17px'>
          <Button isCancleBtn onClick={() => setIsRemoveModal(false)}>
            취소
          </Button>
          <Button onClick={() => onRemove()}>{title}</Button>
        </FlexWrap>
      </Container>
    </BackDrop>
  );
}

const BackDrop = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  min-width: 100vw;
  min-height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 20;
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  background-color: #fff;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.2);
`;
const TemporaryInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-radius: 4px;
  background-color: #f5f6f7;
  font-size: 12px;
  color: #5f6165;
  margin-bottom: 20px;
`;
const Button = styled.div<{ isCancleBtn?: boolean }>`
  width: 100%;
  padding: 14px 0;
  border-radius: 8px;
  background-color: ${(props) => (props.isCancleBtn ? '#E5E6E8' : '#FF2D55')};
  color: ${(props) => (props.isCancleBtn ? '#3A3B3E' : '#FFF')};
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  text-align: center;
  :hover {
    opacity: 0.8;
  }
`;
export default RemoveModal;
