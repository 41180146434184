import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { AxiosError } from 'axios';

import { SideNavigationInfo } from '@/constants/sideNavigation';
import SideMenuItem from './SideMenuItem';
import Naviagtion from '../navigation';
import { logOut } from '@/api/user';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { loginUserState } from '@/store/modules/user';
import defaultProfileIcon from '@/assets/sideBar/defaultProfile.png';
import {
  axiosRequestConfiguration,
  KIOSK_SERVER_TEST,
  SERVER_TEST,
} from '@/api/config';
import { validateReset } from '@/store/modules/validate';
import { reset } from '@/store/modules/refund';
import FlexWrap from '@/components/common/FlexWrap';

function SideNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const name = localStorage.getItem('name');
  const profileImg = localStorage.getItem('profileImg');
  const [isTestServer, setIsTestServer] = useState(false);
  const { isKioskStore } = useAppSelector((state) => state.user);
  const kioskPermission = localStorage.getItem('kioskPermission');

  const isTestServerURL = (baseURL: string) => {
    return baseURL === SERVER_TEST || baseURL === KIOSK_SERVER_TEST;
  };

  useEffect(() => {
    const currentBaseURL: string = axiosRequestConfiguration.baseURL!;
    if (isTestServerURL(currentBaseURL)) {
      setIsTestServer(true);
    }
  }, []);

  useEffect(() => {
    dispatch(validateReset());
    dispatch(reset());
  }, [location]);

  const { refetch } = useQuery<null, AxiosError>('logOut', logOut, {
    enabled: false,
    retry: false,
    onSuccess: () => {
      localStorage.setItem('loginToken', '');
      dispatch(loginUserState(false));
      navigate('/');
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const onLogOut = () => {
    refetch();
    window.localStorage.removeItem('name');
    window.localStorage.removeItem('profileImg');
  };

  return (
    <Wrapper isTestServer={isTestServer}>
      <Container>
        <Naviagtion isTestServer={isTestServer} />
        <Profile>
          <ProfileImage
            src={profileImg ? profileImg : defaultProfileIcon}
            alt='프로필.'
            width='48px'
            height='48px'
          />
          <ProfileContent>
            안녕하세요.
            <br />
            <Bold>{name}</Bold> 님
          </ProfileContent>
        </Profile>
        <List>
          {SideNavigationInfo.map((item) => (
            <Link to={item.link} key={item.id}>
              {item.id === 7 &&
              (!isKioskStore || kioskPermission === 'USER') ? (
                <></>
              ) : (
                <SideMenuItem menuItem={item} />
              )}
            </Link>
          ))}
        </List>
      </Container>
      <BtnContainer dir='column' gap='12px'>
        <LogOutBtn onClick={onLogOut}>
          <span>로그아웃</span>
        </LogOutBtn>
      </BtnContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div<{ isTestServer: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 40px;
  position: relative;
  top: 0px;
  min-width: 280px;
  min-height: 100vh;
  background-color: ${(props) => (props.isTestServer ? 'green' : '#0b42ad')};
  z-index: 10;
  @media ${(props) => props.theme.laptop} {
    min-width: 200px;
  }
`;
const Container = styled.div`
  display: flex;
  flex-direction: column;
`;
const List = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const BtnContainer = styled(FlexWrap)`
  width: 100%;
  justify-content: center;
  padding: 0 20px;
`;
const LogOutBtn = styled.button`
  width: 100%;
  padding: 10px 0px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 2px solid #c7e0f1;
  border-radius: 2px;
  color: #c7e0f1;
`;
const LinkBtn = styled.a`
  text-align: center;
  width: 100%;
  padding: 10px 0px;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 2px solid #c7e0f1;
  border-radius: 2px;
  color: #c7e0f1;
`;
const Profile = styled.div`
  display: flex;
  align-items: center;
  gap: 18px;
  padding: 40px 20px;
  @media ${(props) => props.theme.laptop} {
    flex-direction: column;
    align-items: flex-start;
    padding: 30px 40px;
  }
`;

const ProfileImage = styled.img`
  object-fit: cover;
  border-radius: 100px;
`;

const ProfileContent = styled.span`
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: ${(props) => props.theme.lineHeight.small};
  color: ${(props) => props.theme.pointColors.white};
`;

const Bold = styled.span`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.small};
    line-height: ${(props) => props.theme.lineHeight.small};
  }
`;

export default SideNavigation;
