import { combineReducers } from 'redux';
import refund from './refund';
import validate from './validate';
import user from './user';
import notice from './notice';
import modal from './modal';
import loading from '../loading.store';

const rootReducer = combineReducers({
	refund,
	validate,
	user,
	notice,
	modal,
	loading,
});
export default rootReducer;
