import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import Typography from './Typography';
import downArrowIcon from '@/assets/common/downArrow.png';
import {
  setFilterHospital,
  setFilterProductType,
} from '@/store/modules/refund';
import { HospitalsType } from '@/types/user';

type HospitalsFilterProps = {
  setPageNumber?: Dispatch<SetStateAction<number>>;
};

function HospitalsFilter({ setPageNumber }: HospitalsFilterProps) {
  const [hospitalList, setHospitalList] = useState<HospitalsType[]>([]);
  const [isDrop, setIsDrop] = useState(false);

  const { filter } = useAppSelector<any>((state) => state.refund);
  const { hospitals } = useAppSelector((state) => state.user);

  const dispatch = useAppDispatch();

  const onMouseDown = (hospital: HospitalsType) => {
    setIsDrop(false);
    dispatch(setFilterHospital(hospital));
    dispatch(setFilterProductType(''));
    setPageNumber && setPageNumber(0);
  };

  useEffect(() => {
    const newArray = [
      { hospitalId: '', hospitalName: '구분(전체)' },
      ...hospitals,
    ];
    setHospitalList(newArray);
  }, []);

  return (
    <Container>
      <DropContainer
        onClick={() => setIsDrop((prev) => !prev)}
        onBlur={() => setIsDrop(false)}
      >
        <Select
          value={filter.hospitals.hospitalName}
          placeholder='구분(전체)'
          readOnly
        />
        <ImageContainer>
          <Image src={downArrowIcon} />
        </ImageContainer>
      </DropContainer>

      <DropList isDrop={isDrop}>
        {hospitalList &&
          hospitalList.map((option) => (
            <DropItem
              key={option.hospitalId}
              onMouseDown={() => onMouseDown(option)}
            >
              <Typography>{option.hospitalName}</Typography>
            </DropItem>
          ))}
      </DropList>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  text-align: center;
`;

const DropContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
`;

const Select = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  text-align: center;
  cursor: pointer;
  color: #242424;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  ::placeholder {
    text-align: center;
    color: #242424;
  }
`;
const DropList = styled.ul<{ isDrop: boolean }>`
  position: absolute;
  top: 40px;
  padding: 20px 16px;
  width: 100%;
  max-height: 170px;
  border-radius: 4px;
  overflow-y: auto;
  flex-direction: column;
  z-index: 10;
  display: ${(props) => (props.isDrop ? 'flex' : 'none')};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const DropItem = styled.li`
  cursor: pointer;
  padding: 5px 0;
  :hover {
    background-color: ${(props) => props.theme.priamryColors.primaryVarient};
    span {
      color: ${(props) => props.theme.priamryColors.primary};
    }
  }
`;
const ImageContainer = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

const Image = styled.img`
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export default HospitalsFilter;
