import { AxiosError } from 'axios';
import { useMutation, useQueryClient } from 'react-query';

import { deleteVideo } from '@/api/kiosk';
import { DeleteVideoPayload } from '@/types/kiosk';

type Props = {
  onClose: () => void;
};

function useDeleteKioskVideo({ onClose }: Props) {
  const queryClient = useQueryClient();

  return useMutation<null, AxiosError, DeleteVideoPayload>(
    (payload) => deleteVideo(payload),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('kioskVideoList');
        onClose();
      },
      onError: () => {
        alert('서버와 통신이 원활하지 않습니다. 다시 시도해주세요.');
      },
    }
  );
}

export default useDeleteKioskVideo;
