import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  previewContent: {
    title: '',
    scheduledDate: '',
    writer: '',
    content: '',
    noticeIndex: '',
  },
  currentContent: {
    title: '',
    scheduledDate: '',
    writer: '',
    content: '',
    noticeIndex: '',
  },
  nextContent: {
    title: '',
    scheduledDate: '',
    writer: '',
    content: '',
    noticeIndex: '',
  },
};

const noticeSlice = createSlice({
  name: 'notice',
  initialState,
  reducers: {
    noticeState: (state, action) => {
      state.previewContent = action.payload.preview;
      state.currentContent = action.payload.current;
      state.nextContent = action.payload.next;
    },
  },
});

export const { noticeState } = noticeSlice.actions;
export default noticeSlice.reducer;
