import React, { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import styled from 'styled-components';

import SupplyInputBtn from './SupplyInputBtn';
import removeIcon from '@/assets/common/remove.png';
import SelectBox from '@/components/common/SelectBox';
import { useAppSelector } from '@/hooks/useReduxHooks';
import {
  getComma,
  getSupplyPaymentPrice,
  getVatPaymentPrice,
} from '@/util/format';

interface IProps {
  index: number;
  onRemove: (index: number) => void;
}

interface IChange {
  index: number;
  e: React.ChangeEvent<HTMLInputElement>;
}

function SupplyInputItem({ index, onRemove }: IProps) {
  const methods = useFormContext();
  const { productTypes } = useAppSelector((state) => state.user);
  const isSurgery =
    productTypes.length === 1
      ? true
      : String(methods.getValues(`supply.${index}.productType`));
  const { temporaryStorage } = useAppSelector((state) => state.refund);

  // 임시저장
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      let newValue = '';
      temporaryStorage.supply.forEach((item: any, idx) => {
        if (index == idx) {
          newValue = item.price;
        }
      });
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue.replace(/(,)/g, '')))
      );
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue.replace(/(,)/g, ''),
            getSupplyPaymentPrice(newValue.replace(/(,)/g, '')).toString()
          )
        )
      );
    }
  }, [temporaryStorage]);

  useEffect(() => {
    if (isSurgery) {
      setTimeout(() => {
        methods.setFocus(`supply.${index}.price`);
      }, 100);
    }
  }, [isSurgery]);

  const onChange = ({ e: { target }, index }: IChange) => {
    // 금액이 0으로 시작하는거 방지
    const newtarget =
      target.value.charAt(0) === '0' ? target.value.substring(1) : target.value;
    methods.setValue(`supply.${index}.price`, newtarget.replace(/[^0-9]/g, ''));

    let newValue = target.value.replace(/(,)/g, '');
    if (+newValue === 0) {
      // 결제금액
      methods.setValue(`supply.${index}.price`, '0');
      // 공급가액
      methods.setValue(`supply.${index}.supplyPrice`, '0');
      // 부가세
      methods.setValue(`supply.${index}.vatPrice`, '0');
    } else {
      // 결제금액
      methods.setValue(`supply.${index}.price`, getComma(newValue));
      // 공급가액
      methods.setValue(
        `supply.${index}.supplyPrice`,
        getComma(getSupplyPaymentPrice(newValue))
      );
      // 부가세
      methods.setValue(
        `supply.${index}.vatPrice`,
        getComma(
          getVatPaymentPrice(
            newValue,
            getSupplyPaymentPrice(newValue).toString()
          )
        )
      );
    }
  };

  return (
    <Wrapper>
      <InputItem>
        <Item flex={1}>{index < 9 ? '0' + (index + 1) : index + 1}</Item>
        <Item flex={4}>
          <SelectBox name={`supply.${index}.productType`} index={index} />
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.supplyPrice`)}
            placeholder='공급가액'
            disabled
          />{' '}
          원
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.vatPrice`)}
            placeholder='부가세액'
            disabled
          />
          원
        </Item>
        <Item flex={3}>
          <Input
            {...methods.register(`supply.${index}.price`, {
              onChange: (e) => onChange({ e, index }),
            })}
            disabled={Boolean(!isSurgery)}
            placeholder='금액 입력'
            autoComplete='off'
          />
          원
        </Item>
        <SupplyInputBtn index={index} />
      </InputItem>
      <RemoveWrapper id='no_focus'>
        <RemoveIcon src={removeIcon} onClick={() => onRemove(index)} />
        삭제
      </RemoveWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div``;

const InputItem = styled.li`
  display: flex;
  align-items: center;
  border-bottom: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const Item = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
  gap: 10px;
  flex: ${(props) => props.flex};
  line-height: ${(props) => props.theme.lineHeight.large};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :first-child {
    border-left: 1px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

const Input = styled.input`
  width: 85%;
  height: 32px;
  padding: 5px 14px;
  border-radius: 4px;
  text-align: right;
  outline: none;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  ::placeholder {
    text-align: right;
    color: ${(props) => props.theme.grayColors.scale3};
  }
`;

const RemoveWrapper = styled.button`
  display: flex;
  align-items: center;
  gap: 4px;
  position: absolute;
  top: 0;
  right: 0;
  color: #757575;
`;

const RemoveIcon = styled.img`
  width: 30px;
  height: 30px;
  padding: 2px;
  cursor: pointer;
`;

export default SupplyInputItem;
