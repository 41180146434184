import { routes } from '@/routes';
import homeIcon from '@/assets/sideBar/home.png';
import homeIconActive from '@/assets/sideBar/homeActive.png';
import refundIcon from '@/assets/sideBar/refund.png';
import refundActiveIcon from '@/assets/sideBar/refundActive.png';
import refundStatementIcon from '@/assets/sideBar/refundStatement.png';
import refundStatementIconActive from '@/assets/sideBar/refundStatementActive.png';
import refundDetailIcon from '@/assets/sideBar/refundDetail.png';
import refundDetailIconActive from '@/assets/sideBar/refundDetailActive.png';
import medicalIcon from '@/assets/sideBar/medical.png';
import medicalIconActive from '@/assets/sideBar/medicalActive.png';
import noticeIcon from '@/assets/sideBar/notice.png';
import noticeIconActive from '@/assets/sideBar/noticeActive.png';
import kioskIcon from '@/assets/kiosk/kiosk.png';
import kioskIconActive from '@/assets/kiosk/kiosk_active.png';

export const SideNavigationInfo = [
  {
    id: 1,
    title: '홈',
    uri: { active: homeIconActive, notActive: homeIcon },
    link: routes.home,
  },
  {
    id: 2,
    title: '환급하기',
    uri: { active: refundActiveIcon, notActive: refundIcon },
    link: routes.refund,
  },
  {
    id: 3,
    title: '환급내역',
    uri: { active: refundDetailIconActive, notActive: refundDetailIcon },
    link: routes.refundDetail,
  },
  {
    id: 4,
    title: '환급 내역서',
    uri: { active: refundStatementIconActive, notActive: refundStatementIcon },
    link: routes.refundStatement,
  },
  {
    id: 5,
    title: '의료기관 정보',
    uri: { active: medicalIconActive, notActive: medicalIcon },
    link: routes.medicalInfo,
  },
  {
    id: 6,
    title: '공지사항',
    uri: { active: noticeIconActive, notActive: noticeIcon },
    link: routes.notice,
  },
  {
    id: 7,
    title: '키오스크 설정',
    uri: { active: kioskIconActive, notActive: kioskIcon },
    link: routes.kiosk,
  },
];
