import React from 'react';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';

interface IProps {
  width?: string;
  height?: string;
  text?: string;
  padding?: string;
  border?: string;
  borderRadius?: string;
  fontSize?: string;
  backgroundColor?: string;
  color?: string;
  disabled?: boolean;
  margin?: string;
  fontWeight?: boolean;
  onClick?: () => void;
}

function Button({
  width,
  height,
  text,
  padding,
  border,
  borderRadius,
  backgroundColor,
  color,
  disabled,
  onClick,
  margin,
  fontWeight = false,
}: IProps) {
  return (
    <FlexWrap alignItems='center'>
      <Btn
        width={width}
        height={height}
        padding={padding}
        border={border}
        backgroundColor={backgroundColor}
        borderRadius={borderRadius}
        margin={margin}
        onClick={onClick}
        disabled={disabled}
      >
        <Text color={color} bold={fontWeight}>
          {text}
        </Text>
      </Btn>
    </FlexWrap>
  );
}

const Btn = styled.button<{
  width?: string;
  height?: string;
  padding?: string;
  backgroundColor?: string;
  borderRadius?: string;
  border?: string;
  margin?: string;
}>`
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  border: ${(props) => props.border && props.border};
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
`;

const Text = styled.span<{ color?: string; bold?: boolean }>`
  color: ${(props) => props.color && props.color};
  font-weight: ${(props) => props.bold && props.theme.fontWeight.medium};
`;

export default Button;
