import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';
import cancleImage from '@/assets/common/cancle.png';

interface IProps {
  width: string;
  height: string;
  setIsModal: Dispatch<SetStateAction<boolean>>;
}

function Cancle({ width, height, setIsModal }: IProps) {
  return (
    <Icon
      src={cancleImage}
      onClick={() => setIsModal(false)}
      width={width}
      height={height}
    />
  );
}

const Icon = styled.img<{ width: string; height: string }>`
  cursor: pointer;
`;

export default Cancle;
