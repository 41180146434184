import React, { useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
import styled, { css } from 'styled-components';

import cancleImage from '@/assets/common/cancle.png';
import {
  RefundDetailFullPaymentHeader,
  RefundDetailHeader,
} from '@/constants/refundGraph';
import { getComma } from '@/util/format';
import FlexWrap from '../common/FlexWrap';
import RefundPrint from '../refund/PaymentComplete/RefundPrint';
import PrintElement from '../refund/PrintElement';
import { useAppSelector } from '@/hooks/useReduxHooks';
import Typography from '../common/Typography';
import Icon from '../common/Icon';
import noticeIcon from '@/assets/refund/noticeRed.png';

function DetailModal({ setUploadModal, refund }: any) {
  const [isPreview, setIsPreview] = useState(false);
  const printRef = useRef(null);
  const { isAutoPayment } = useAppSelector((state) => state.user);

  return (
    <Wrapper>
      <BackDrop onClick={() => setUploadModal(false)} />
      <Container>
        <FlexWrap alignItems='center'>
          <Title>환급 개요</Title>
          {isAutoPayment && refund.paymentType === 'DIRECT' && (
            <FlexWrap alignItems='center' margin='0 0 0 20px' gap='8px'>
              <Icon imgUrl={noticeIcon} width='16px' height='16px' />
              <Typography color='#FF2D55'>
                환급 규정에 따라 일부 특정국가 고객 및 금액에 따라 부가세 포함
                된 금액으로 결제액을 받습니다.
              </Typography>
            </FlexWrap>
          )}
          <Cancle
            src={cancleImage}
            onClick={() => setUploadModal({ isActive: false })}
          />
        </FlexWrap>
        <ContentContainer>
          <Content>
            {(isAutoPayment
              ? RefundDetailHeader
              : RefundDetailFullPaymentHeader
            ).map((item) => (
              <ContentText key={item.id} isHeader>
                {item.title}
              </ContentText>
            ))}
          </Content>
          <Content>
            <ContentText>{refund.supplyDate}</ContentText>
            <ContentText>{refund.nation}</ContentText>
            <ContentText>{refund.touristName}</ContentText>
            {isAutoPayment ? (
              <>
                <ContentText textRight>
                  {getComma(refund.totalPrice)}원
                </ContentText>
                <ContentText textRight>{getComma(refund.refund)}원</ContentText>
                <ContentText isColor>
                  {getComma(
                    refund.paymentType === 'ORIGINAL'
                      ? +refund.totalPrice - +refund.refund
                      : +refund.totalPrice
                  )}
                  원
                </ContentText>
              </>
            ) : (
              <>
                <ContentText>{getComma(refund.totalPrice)}원</ContentText>
                <ContentText textRight>{getComma(refund.refund)}원</ContentText>
              </>
            )}
          </Content>
        </ContentContainer>
        {isPreview ? (
          <>
            <PreviewWrapper>
              <PreviewContainer>
                <Cancle src={cancleImage} onClick={() => setIsPreview(false)} />
                <div ref={printRef}>
                  <PrintElement
                    touristIndex={refund.touristIndex}
                    refundIndex={refund.refundIndex}
                  />
                </div>
                <ButtonWrapper>
                  <ReactToPrint
                    trigger={() => <PrintBtn isPrint>출력</PrintBtn>}
                    content={() => printRef.current}
                  />
                  <PrintBtn onClick={() => setIsPreview(false)}>확인</PrintBtn>
                </ButtonWrapper>
              </PreviewContainer>
            </PreviewWrapper>
          </>
        ) : (
          <>
            <FlexWrap alignItems='center' gap='12px'>
              <Title>환급 서류</Title>
            </FlexWrap>
            <Evidence>
              <EvidenceText>의료용역공급확인서</EvidenceText>
              <RefundPrint
                touristIndex={refund.touristIndex}
                refundIndex={refund.refundIndex}
                setIsPreview={setIsPreview}
              />
            </Evidence>
          </>
        )}
      </Container>
    </Wrapper>
  );
}

const PreviewWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  padding: 50px;
  z-index: 30;
  overflow: auto;
`;

const ButtonWrapper = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  justify-content: flex-end;
`;

const PreviewContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  padding: 50px;
  min-height: 100vh;
  background-color: #fff;
`;

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background-color: rgba(33, 33, 33, 0.4);
  z-index: 20;
  overflow: auto;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  position: relative;
  padding: 108px 116px 56px 116px;
  width: 75%;
  min-height: 490px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const Title = styled.h2`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  color: ${(props) => props.theme.grayColors.scale1};
`;

const Cancle = styled.img<{ preview?: boolean }>`
  position: absolute;
  top: 40px;
  right: 40px;
  width: 24px;
  height: 24px;
  cursor: pointer;
  ${(props) =>
    props.preview &&
    css`
      top: 10px;
      right: 10px;
      width: 16px;
      height: 16px;
    `}
`;

const PrintBtn = styled.button<{ isPrint?: boolean }>`
  bottom: 0;
  right: 0;
  padding: 13px 15px;
  border-radius: 2px;
  margin: 20px;
  width: 100px;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  border: 1px solid #212121;
  color: ${(props) =>
    props.isPrint ? props.theme.pointColors.white : '#212121'};
  background-color: ${(props) =>
    props.isPrint ? '#212121' : props.theme.pointColors.white};
`;

const ContentContainer = styled.div`
  margin-bottom: 12px;
  border-radius: 4px;
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
`;

const Content = styled.div`
  display: flex;
  justify-content: center;
  /* align-items: center; */

  :first-child {
    border-bottom: 2px solid ${(props) => props.theme.grayColors.scale3};
  }
`;

const Evidence = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const EvidenceText = styled.span`
  font-size: ${(props) => props.theme.fontSize.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  margin-right: 28px;
`;

const ContentText = styled.p<{
  isHeader?: boolean;
  textRight?: boolean;
  isColor?: boolean;
}>`
  padding: 12px 0;
  flex: 1;
  text-align: center;
  color: ${(props) =>
    props.isColor
      ? props.theme.priamryColors.primary
      : props.theme.grayColors.scale1};
  background-color: ${(props) => props.isHeader && '#EAEFF3'};
  font-size: ${(props) => props.isHeader && props.theme.fontSize.small};
  font-weight: ${(props) => props.isHeader && props.theme.fontWeight.medium};
  line-height: ${(props) => props.isHeader && props.theme.lineHeight.small};
  :not(:last-child) {
    border-right: 2px solid ${(props) => props.theme.grayColors.scale3};
  }
  @media ${(props) => props.theme.laptop} {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const BackDrop = styled.div<{ zIndex?: number }>`
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: ${(props) => props.zIndex};
`;

export default DetailModal;
