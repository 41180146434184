import { AxiosError } from 'axios';
import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from 'react-query';

import { uploadVideo } from '@/api/kiosk';
import { UploadVedioPayload, UploadVedioResponse } from '@/types/kiosk';

type Params = {
  closeModal: () => void;
  setIsError: Dispatch<SetStateAction<boolean>>;
};
function useUploadKioskVideo({ closeModal, setIsError }: Params) {
  const queryClient = useQueryClient();

  return useMutation<UploadVedioResponse, AxiosError, UploadVedioPayload>(
    (payload: UploadVedioPayload) => uploadVideo(payload),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('kioskVideoList');
        closeModal();
      },
      onError: (error) => {
        //@ts-ignore
        if (error?.response?.data?.code === 'KV0004') {
          setIsError(true);
        } else {
          alert('영상 업로드에 실패하였습니다.');
        }
      },
    }
  );
}

export default useUploadKioskVideo;
