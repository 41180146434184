import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import Typography from './Typography';
import { ProductTypeList } from '@/constants/supply';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { setFilterProductType } from '@/store/modules/refund';
import downArrowIcon from '@/assets/common/downArrow.png';

interface IDropList {
  key: number;
  value: string;
  name: string;
}

type SupplyFilterProps = {
  setPageNumber?: Dispatch<SetStateAction<number>>;
};

function SupplyFilter({ setPageNumber }: SupplyFilterProps) {
  const [isDrop, setIsDrop] = useState(false);
  const [medicalTypeList, setMedicalTypeList] = useState<
    IDropList[] | undefined
  >([]);

  const { productTypes } = useAppSelector<any>((state) => state.user);
  const { filter } = useAppSelector<any>((state) => state.refund);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (productTypes.length === 0) {
      const newArray = [
        { key: 20, value: '시술(전체)', name: '' },
        ...ProductTypeList,
      ];
      setMedicalTypeList(newArray);
      return;
    }

    const filterArray = ProductTypeList.filter((item) =>
      productTypes.includes(String(item.key))
    );
    const newArray = [
      { key: 20, value: '시술(전체)', name: '' },
      ...filterArray,
    ];
    setMedicalTypeList(newArray);
  }, []);

  const onMouseDown = (dropList: IDropList) => {
    setIsDrop(false);
    dispatch(setFilterProductType(dropList));
    setPageNumber && setPageNumber(0);
  };

  return (
    <Container>
      <DropContainer
        onClick={() => setIsDrop((prev) => !prev)}
        onBlur={() => setIsDrop(false)}
      >
        <Select
          value={filter.productType.value || ''}
          placeholder='시술(전체)'
          readOnly
          disabled={filter.hospitals.hospitalId}
        />
        <ImageContainer disabled={filter.hospitals.hospitalId}>
          <Image src={downArrowIcon} />
        </ImageContainer>
      </DropContainer>

      <DropList isDrop={isDrop}>
        {medicalTypeList &&
          medicalTypeList.map((option) => (
            <DropItem key={option.key} onMouseDown={() => onMouseDown(option)}>
              <Typography>{option.value}</Typography>
            </DropItem>
          ))}
      </DropList>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 200px;
  text-align: center;
`;

const DropContainer = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 32px;
  position: relative;
`;

const Select = styled.input`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  outline: none;
  text-align: center;
  cursor: pointer;
  color: #242424;
  background-color: #fff;
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
  ::placeholder {
    text-align: center;
    color: #242424;
  }
  :disabled {
    background-color: #f7f9fc;
  }
`;
const DropList = styled.ul<{ isDrop: boolean }>`
  position: absolute;
  top: 40px;
  padding: 20px 16px;
  width: 100%;
  max-height: 170px;
  border-radius: 4px;
  overflow-y: auto;
  flex-direction: column;
  z-index: 10;
  display: ${(props) => (props.isDrop ? 'flex' : 'none')};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
  background-color: ${(props) => props.theme.pointColors.white};
`;

const DropItem = styled.li`
  cursor: pointer;
  padding: 5px 0;
  :hover {
    background-color: ${(props) => props.theme.priamryColors.primaryVarient};
    span {
      color: ${(props) => props.theme.priamryColors.primary};
    }
  }
`;
const ImageContainer = styled.button`
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
`;

const Image = styled.img`
  right: 12px;
  width: 24px;
  height: 24px;
  cursor: pointer;
`;
export default SupplyFilter;
