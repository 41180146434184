import React, { useState } from 'react';
import styled from 'styled-components';

import refundNotImage from '@/assets/refund/refundNot.png';
import HomeNotice from '../home/HomeNotice';

import { useNotice } from '@/hooks/useNotice';

function NoticeList() {
  const [pageNumber, setPageNumber] = useState(0);
  const [isNotice, setIsNotice] = useState(true);
  const { data, totalPage } = useNotice(pageNumber, 10);
  return (
    <>
      {data?.content[0] ? (
        <>
          <HomeNotice
            totalPage={totalPage}
            setPageNumber={setPageNumber}
            medicalNotice={data.content}
            isNotice={isNotice}
            setIsNotice={setIsNotice}
          />
        </>
      ) : (
        <RefundNotContainer>
          <Image src={refundNotImage} />
          <Text>공지 사항이 없습니다.</Text>
        </RefundNotContainer>
      )}
    </>
  );
}

const RefundNotContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  flex: 1;
`;

const Image = styled.img`
  width: 120px;
  height: 120px;
`;

const Text = styled.span`
  font-size: ${(props) => props.theme.fontSize.regular};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.regular};
  color: ${(props) => props.theme.grayColors.scale2};
`;

export default NoticeList;
