import React, { Fragment } from 'react';
import styled from 'styled-components';

import { useAppSelector } from '@/hooks/useReduxHooks';

interface IProps {
  titleList: CardGraph[];
}

interface CardGraph {
  id: number;
  title: string;
  flex: number;
}

function TableHeader({ titleList }: IProps) {
  const { hospitals } = useAppSelector((state) => state.user);
  return (
    <Container>
      {titleList.map((card) => (
        <Fragment key={card.id}>
          {hospitals.length === 0 && card.title === '구분' ? (
            <></>
          ) : (
            <CardTitle flex={card.flex}>{card.title}</CardTitle>
          )}
        </Fragment>
      ))}
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 26px;
  background-color: ${(props) => props.theme.blueColors.scale2};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;

const CardTitle = styled.h5<{ flex: number }>`
  display: flex;
  flex: ${(props) => props.flex};
  justify-content: center;
  padding: 12px 20px;
  font-size: ${(props) => props.theme.fontSize.small};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.small};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :last-child {
    border-right: none;
  }
`;

export default TableHeader;
