import React, { ReactNode } from 'react';
import styled from 'styled-components';

type TypographyProps = {
  size?: string;
  lineHeight?: string;
  fontWeight?: string;
  color?: string;
  margin?: string;
  fontFamily?: string;
  textDecoration?: string;
  background?: string;
  padding?: string;
  children: React.ReactNode;
  center?: boolean;
};

function Typography({
  size,
  lineHeight,
  fontWeight,
  color,
  margin,
  fontFamily,
  textDecoration,
  background,
  padding,
  children,
  center,
}: TypographyProps) {
  return (
    <Container margin={margin} background={background} padding={padding}>
      <Text
        size={size}
        lineHeight={lineHeight}
        fontWeight={fontWeight}
        color={color}
        fontFamily={fontFamily}
        textDecoration={textDecoration}
        center={center}
      >
        {children}
      </Text>
    </Container>
  );
}
const Container = styled.div<{
  margin?: string;
  background?: string;
  padding?: string;
}>`
  display: inline-block;
  margin: ${(props) => props.margin};
  background: ${(props) => props.background};
  padding: ${(props) => props.padding};
`;
const Text = styled.span<{
  size?: string;
  lineHeight?: string;
  fontWeight?: string;
  color?: string;
  textDecoration?: string;
  fontFamily?: string;
  center?: boolean;
}>`
  font-size: ${(props) => props.size};
  line-height: ${(props) => props.lineHeight};
  font-weight: ${(props) => props.fontWeight};
  color: ${(props) => props.color};
  font-family: ${(props) => props.fontFamily};
  text-decoration: ${(props) => props.textDecoration};
  display: block;
  text-align: ${(props) => (props.center ? 'center' : 'left')};
`;

export default Typography;
