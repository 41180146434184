import React from 'react';
import styled from 'styled-components';

import useStatement from '@/hooks/useStatement';
import FlexWrap from '../common/FlexWrap';
import Paging from '../common/Paging';
import RequiredData from './RequiredData';
import RefundVatData from './RefundVatData';

function RefundStatementContent() {
  const {
    data: refundStatementData,
    totalPage,
    totalElements,
    setPageNumber,
  } = useStatement();

  return (
    <Container>
      <Title>환급 내역서</Title>
      <SubContainer>
        <SubTitle>필수 자료</SubTitle>
        <RequiredData />
      </SubContainer>
      <SubContainer>
        <FlexWrap justifyContent='space-between'>
          <SubTitle>환급/부가세 신고자료</SubTitle>
          <CautionText>
            * 부가가치세신고자료는 개인사업자 1,7월 / 법인사업자는 1,4,7,10월에
            업로드됩니다.
          </CautionText>
        </FlexWrap>
        {refundStatementData && (
          <RefundVatData
            refundStatementData={refundStatementData.content}
            totalElements={totalElements}
          />
        )}
      </SubContainer>
      <Paging setPageNumber={setPageNumber} totalPage={totalPage} />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 22px 40px 85px 40px;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;
const SubContainer = styled.div`
  padding: 36px 0px;
`;
const SubTitle = styled.h3`
  font-size: 20px;
  line-height: 28px;
  padding-bottom: 24px;
  font-weight: 500;
`;
const CautionText = styled.p`
  font-size: 14px;
  line-height: 22px;
`;
export default RefundStatementContent;
