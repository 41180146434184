import { createSlice } from '@reduxjs/toolkit';

type ModalStoreType = {
	isLoading: boolean;
};

const initialState: ModalStoreType = {
	isLoading: false,
};

const loadingSlice = createSlice({
	name: 'loading',
	initialState,
	reducers: {
		updateLoading: (state, action) => {
			return { ...state, ...action.payload };
		},
	},
});

export const { updateLoading } = loadingSlice.actions;
export default loadingSlice.reducer;
