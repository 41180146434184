export const RefundGraphHeader = [
  { id: 1, title: 'NO.', flex: 0.7 },
  { id: 2, title: '구분', flex: 1.2 },
  { id: 3, title: '환급상태', flex: 1.8 },
  { id: 4, title: '시술일자', flex: 2 },
  { id: 5, title: '국적', flex: 1.5 },
  { id: 6, title: '고객 성함', flex: 3.5 },
  { id: 7, title: '의료금액', flex: 3 },
  { id: 8, title: '환급액', flex: 2 },
  { id: 9, title: '최종 결제금액', flex: 3 },
  { id: 10, title: '상세보기', flex: 2.2 },
  { id: 11, title: '환급 취소', flex: 2 },
];

export const RefundGraphFullPaymentHeader = [
  { id: 1, title: 'NO.', flex: 0.7 },
  { id: 2, title: '구분', flex: 1.2 },
  { id: 3, title: '환급상태', flex: 1.8 },
  { id: 4, title: '시술일자', flex: 2 },
  { id: 5, title: '국적', flex: 1.5 },
  { id: 6, title: '고객 성함', flex: 3.5 },
  { id: 7, title: '결제금액', flex: 3 },
  { id: 8, title: '예상 환급액', flex: 2 },
  { id: 9, title: '상세보기', flex: 2.2 },
  { id: 10, title: '환급 취소', flex: 2 },
];

export const RefundDetailHeader = [
  { id: 1, title: '시술일자', flex: 1 },
  { id: 2, title: '국적', flex: 1 },
  { id: 3, title: '고객 성함', flex: 1 },
  { id: 4, title: '의료금액', flex: 1 },
  { id: 5, title: '환급액', flex: 1 },
  { id: 6, title: '최종 결제금액', flex: 1 },
];
export const RefundDetailFullPaymentHeader = [
  { id: 1, title: '시술일자', flex: 1 },
  { id: 2, title: '국적', flex: 1 },
  { id: 3, title: '고객 성함', flex: 1 },
  { id: 6, title: '결제금액', flex: 1 },
  { id: 5, title: '예상 환급액', flex: 1 },
];

export const RefundHomeHeader = [
  { id: 1, title: 'No.', flex: 1 },
  { id: 2, title: '구분', flex: 1.5 },
  { id: 3, title: '시술일자', flex: 2.5 },
  { id: 4, title: '국적', flex: 2.5 },
  { id: 5, title: '고객성함', flex: 2.5 },
  { id: 6, title: '의료금액', flex: 2.5 },
  { id: 7, title: '환급액', flex: 2.5 },
  { id: 8, title: '최종 결제금액', flex: 2.5 },
  { id: 9, title: '상세보기', flex: 2.5 },
];

export const RefundHomeFullPaymentHeader = [
  { id: 1, title: 'No.', flex: 1 },
  { id: 2, title: '구분', flex: 1.5 },
  { id: 3, title: '시술일자', flex: 2.5 },
  { id: 4, title: '국적', flex: 2.5 },
  { id: 5, title: '고객성함', flex: 2.5 },
  { id: 6, title: '결제금액', flex: 2.5 },
  { id: 7, title: '예상환급액', flex: 2.5 },
  { id: 8, title: '상세보기', flex: 2.5 },
];

export const NoticeHomeHeader = [
  { id: 1, title: 'No.', flex: 1 },
  { id: 2, title: '제목', flex: 12 },
  { id: 3, title: '게시 일자', flex: 1.5 },
  { id: 4, title: '작성자', flex: 1.5 },
];
