import React from 'react';
import styled from 'styled-components';

import { IMedicalResponse } from '@/types/medical';
import FlexWrap from '../common/FlexWrap';
import Text from '../common/Text';

interface IProps {
  medicalData: IMedicalResponse;
}

function MedicalAccount({ medicalData }: IProps) {
  return (
    <Container gap='12px' dir='column' flex='1'>
      <Title>CMS 자동 출금 계좌 정보</Title>
      <Text text='출금 계좌 정보 수정이 필요하신 경우, 상담센터 (02-6275-8011)로 문의주시길 바랍니다.' />
      <AccountContent>
        <InputContainer>
          <Text text='예금주명' fontWeight='500' />
          <Input value={medicalData.hospitalInfoDto.managerName} />
        </InputContainer>
        <InputContainer>
          <Text text='은행명' fontWeight='500' />
          <Input value={medicalData.hospitalInfoDto.bankName} />
        </InputContainer>
        <InputContainer>
          <Text text='계좌번호' fontWeight='500' />
          <Input value={medicalData.hospitalInfoDto.accountNumber} />
        </InputContainer>
      </AccountContent>
    </Container>
  );
}

const Container = styled(FlexWrap)``;

const Title = styled.h3`
  font-size: ${(props) => props.theme.fontSize.medium};
  font-weight: ${(props) => props.theme.fontWeight.medium};
  line-height: ${(props) => props.theme.lineHeight.medium};
  margin-bottom: 18px;
`;

const AccountContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-top: 24px;
  padding: 30px 20px;
  width: 100%;
  height: 315px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

const Input = styled.input.attrs({ disabled: true })`
  width: 20%;
  padding: 13px 10px;
  border-radius: 4px;
  color: ${(props) => props.theme.grayColors.scale2};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
`;

export default MedicalAccount;
