import React, { useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQueryClient } from 'react-query';

import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { updateRefundInfo } from '@/store/modules/refund';
import {
  passportCheckState,
  storageModalState,
} from '@/store/modules/validate';
import { comma } from '@/util/format';
import { temporaryStorageRemove } from '@/api/refund';
import RemoveModal from './RemoveModal';
import { useFormContext } from 'react-hook-form';

function StorageItem({ item, legnth, index }: any) {
  const [isRemoveModal, setIsRemoveModal] = useState(false);
  const { hospitalId } = useAppSelector((state) => state.user);
  const dispatch = useAppDispatch();
  const { tempInformation, createdDate, tempInformationId } = item;
  const queryClient = useQueryClient();
  const { setFocus } = useFormContext();

  const onLoad = async () => {
    setFocus('touristName');
    dispatch(storageModalState(false));
    dispatch(passportCheckState(false));
    dispatch(
      updateRefundInfo({
        temporaryStorage: {
          supplyDate: tempInformation.supplyDate, //
          touristName: tempInformation.name,
          passportNumber: tempInformation.passportNumber,
          nationality: tempInformation.nation,
          totalPrice: tempInformation.totalPrice,
          supply: tempInformation.orderInfoList,
          paymentCash: tempInformation.paymentCash,
          paymentCard: tempInformation.paymentCard,
          birthYear: tempInformation.year,
          birthMonth: tempInformation.month,
          birthDay: tempInformation.day,
          tempInformationId,
        },
      })
    );
  };

  const { mutate: removeMutaion } = useMutation(
    () => temporaryStorageRemove(hospitalId, item.tempInformationId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('allStorage');
        setIsRemoveModal(false);
      },
    }
  );

  const onRemove = () => {
    removeMutaion();
  };

  const calculatePrice = () => {
    let totalPrice = 0;
    tempInformation.orderInfoList.forEach((item: any) => {
      totalPrice += +item.price.replace(/(,)/g, '');
    });
    return totalPrice;
  };

  return (
    <>
      <ItemContainer>
        <Item flex={1}>{legnth - index || '-'}</Item>
        <Item flex={2.3}>{createdDate.slice(0, 10) || '-'}</Item>
        <Item flex={1.3}>{tempInformation.nation || '-'}</Item>
        <Item flex={2.5}>{tempInformation.name || '-'}</Item>
        <Item flex={2.5}>{tempInformation.passportNumber || '-'}</Item>
        <Item flex={2.5}>
          {tempInformation.orderInfoList?.length > 0
            ? comma(calculatePrice()) + '원'
            : '-'}
        </Item>
        <Item flex={2}>
          <RemoveBtn onClick={() => setIsRemoveModal(true)}>삭제하기</RemoveBtn>
        </Item>
        <Item flex={2.5}>
          <LoadBtn onClick={() => onLoad()}>불러오기</LoadBtn>
        </Item>
      </ItemContainer>
      {isRemoveModal && (
        <RemoveModal
          title='삭제하기'
          text='임시 저장 내역을 삭제하시겠습니까?'
          data={tempInformation}
          createdDate={createdDate}
          setIsRemoveModal={setIsRemoveModal}
          onRemove={onRemove}
        />
      )}
    </>
  );
}

const ItemContainer = styled.div`
  display: flex;
  padding: 12px 20px;
  border-bottom: 1px solid #e5e6e8;
`;
const Item = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: ${(props) => props.flex};
`;
const RemoveBtn = styled.div`
  padding: 9px 25px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background-color: #e5e6e8;
  color: #3a3b3e;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
const LoadBtn = styled.div`
  padding: 9px 40px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 600;
  background-color: #246cf6;
  color: #fff;
  border-radius: 8px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export default StorageItem;
