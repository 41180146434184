import { useMutation, useQueryClient } from 'react-query';
import { AxiosError } from 'axios';

import { activateVideo } from '@/api/kiosk';
import { ActivateVideoPayload } from '@/types/kiosk';

type Params = {
  callback?: () => void;
};
function useActivateKioskVideo({ callback }: Params) {
  const queryClient = useQueryClient();
  return useMutation<null, AxiosError, ActivateVideoPayload>(
    (payload) => activateVideo(payload),
    {
      retry: false,
      onSuccess: () => {
        queryClient.invalidateQueries('kioskVideoList');
        callback && callback();
      },
      onError: () => {
        alert('서버와의 통신이 원활하지 않습니다. 다시 한번 시도해주세요.');
      },
    }
  );
}

export default useActivateKioskVideo;
