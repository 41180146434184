import styled from 'styled-components';

import AlertIcon from '@/assets/common/alert.png';
import FlexWrap from '@/components/common/FlexWrap';

function RenewalNotification() {
  const accessToken = localStorage.getItem('loginToken');

  return (
    <ToastMessage>
      <FlexWrap alignItems='center' gap='12px'>
        <Image src={AlertIcon} />
        <ToastText>리뉴얼 된 신버전 환급웹을 사용해 보세요</ToastText>
      </FlexWrap>
      <LinkBtn
        href={`https://ktpmedical.ktaxpay.com/auto-login/${accessToken}`}
      >
        신버전 환급웹 바로가기
      </LinkBtn>
    </ToastMessage>
  );
}

const ToastMessage = styled.div`
  width: 100%;
  background-color: #d3e2fd;

  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 40px;
  font-weight: bold;
  font-size: 16px;
`;
const ToastText = styled.p`
  color: #1856cd;
  margin-top: 2px;
`;
const Image = styled.img`
  width: 20px;
  height: 20px;
`;
const LinkBtn = styled.a`
  background-color: #1856cd;
  border-radius: 8px;
  padding: 8px 16px;
  color: white;
  :hover {
    opacity: 0.7;
  }
`;
export default RenewalNotification;
