import { useQuery } from 'react-query';

import { fetchVideoList } from '@/api/kiosk';
import { useAppSelector } from './useReduxHooks';

function useKioskVideoList() {
  const { isKioskStore } = useAppSelector((state) => state.user);

  return useQuery('kioskVideoList', () => fetchVideoList(isKioskStore), {
    retry: false,
  });
}

export default useKioskVideoList;
