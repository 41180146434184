import React from 'react';
import styled from 'styled-components';

import { RefundVatDateHeaderTitle } from '@/constants/refundStatement';
import RefundVatDataItem from './RefundVatDataItem';

type RefundVatDataProps = {
  refundStatementData: any[];
  totalElements: number;
};

function RefundVatData({
  refundStatementData,
  totalElements,
}: RefundVatDataProps) {
  return (
    <>
      <TableHeader>
        {RefundVatDateHeaderTitle.map((item, idx) => (
          <Header key={idx} flex={item.flex}>
            <Title>{item.title}</Title>
          </Header>
        ))}
      </TableHeader>
      <GraphList>
        {!refundStatementData[0] ? (
          <span>신고자료 없음</span>
        ) : (
          refundStatementData.map((item, idx) => (
            <RefundVatDataItem
              key={idx}
              idx={idx}
              refundStatementData={item}
              totalElements={totalElements}
            />
          ))
        )}
      </GraphList>
    </>
  );
}

const TableHeader = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  background-color: ${(props) => props.theme.blueColors.scale2};
  border: 1px solid ${(props) => props.theme.grayColors.scale3};
`;
const Header = styled.div<{ flex: number }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px 20px;
  position: relative;
  flex: ${(props) => props.flex};
  border-right: 1px solid ${(props) => props.theme.grayColors.scale3};
  :last-child {
    border-right: none;
  }
`;
const Title = styled.p`
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
`;
const GraphList = styled.ul``;

export default RefundVatData;
