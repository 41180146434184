export const routes = {
  home: '/',
  refund: '/refund',
  refundDetail: '/refund-detail',
  refundStatement: '/refund-statement',
  medicalInfo: '/medical-info',
  notice: '/notice',
  noticeDetail: '/notice/detail',
  kiosk: '/kiosk',
};
