import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { useMutation, useQueryClient } from 'react-query';
import styled, { css } from 'styled-components';
import { AxiosError } from 'axios';

import { refundRegister, temporaryStorageSave } from '@/api/refund';
import {
  IRefundPayload,
  IRefundResponse,
  ISubmitData,
  StoragePayload,
} from '@/types/refund';
import AmountDetail from './AmountDetail';
import SupplyDetail from './SupplyDetail';
import TouristInput from './TouristInput';
import { getRefundAmount } from '@/util/getRefundAmount';
import { useAppDispatch, useAppSelector } from '@/hooks/useReduxHooks';
import { refundIndexState } from '@/store/modules/user';
import LoadingView from '../common/LoadingView';
import { reset } from '@/store/modules/refund';
import DirectModal from './DirectModal';
import FlexWrap from '../common/FlexWrap';
import callIcon from '@/assets/common/call.png';
import kakaoIcon from '@/assets/common/kakao.png';
import Icon from '../common/Icon';
import Typography from '../common/Typography';
import getCurrentTime, { getNumberFullTime } from '@/util/getCurrentTime';
import { attachedHyphensDate, convertToKoreanUnit } from '@/util/format';
import { updateModal } from '@/store/modules/modal';
import confirmIcon from '@/assets/common/confirm.png';
import { updateLoading } from '@/store/loading.store';

const orderInfoList = {
  productType: '',
  price: '',
  supplyPrice: '',
  vatPrice: '',
  isCheck: false,
};

interface IProps {
  setIsRefund: Dispatch<SetStateAction<boolean>>;
  isExpired: boolean;
}

interface ISupplyItem {
  price: string;
  productType: string | number;
  supplyPrice: string;
  vatPrice: string;
  isCheck?: boolean;
}

function RefundContent({ setIsRefund, isExpired }: IProps) {
  const [isPayment, setIsPayment] = useState(true);
  const [leafletModalInfo, setLeafletModalInfo] = useState({
    active: false,
    isOver550: false,
    isKioskError: false,
  });
  const [errorMessage, setErrorMessage] = useState({
    isSubmit: false,
    message: '',
  });
  const [isFetchedStorageData, setIsFetchedStorageData] = useState(false);
  const { touristIndex, isAutoPayment, isKioskStore, hospitalId } =
    useAppSelector((state) => state.user);
  const { isDirectPayment, isScanned, temporaryStorage } = useAppSelector(
    (state) => state.refund,
  );
  const { isPassportCheck } = useAppSelector((state) => state.validate);
  const dispatch = useAppDispatch();
  // 환급하기 할때 서버로 보내는 body 값
  const methods = useForm({
    defaultValues: {
      touristName: '',
      departureDate: '',
      paymentCard: '0',
      paymentCash: '0',
      supply: [orderInfoList, orderInfoList, orderInfoList],
      supplyDate: attachedHyphensDate(getNumberFullTime(new Date())),
      name: '',
      nationality: '',
      passportNumber: '',
      saleDate: getCurrentTime(),
      birthYear: '',
      birthMonth: { label: '', value: '' },
      birthDay: '',
    },
  });
  const { handleSubmit, setValue, setFocus } = methods;

  // 임시저장 값 불러오기
  useEffect(() => {
    if (temporaryStorage.supplyDate) {
      setValue('supplyDate', temporaryStorage.supplyDate);
      setValue('touristName', temporaryStorage.touristName);
      setValue('passportNumber', temporaryStorage.passportNumber);
      setValue('nationality', temporaryStorage.nationality);
      setIsFetchedStorageData(true);
    }
  }, [temporaryStorage]);

  const queryClient = useQueryClient();
  const { mutate: storageMutation } = useMutation<
    number,
    AxiosError,
    StoragePayload
  >((payload) => temporaryStorageSave(payload), {
    retry: false,
    onSuccess: () => {
      dispatch(
        updateModal({
          isActive: true,
          type: 'CUSTOM',
          data: {
            icon: confirmIcon,
            title: '임시 저장 성공',
            content: '입력하신 정보를 임시저장했습니다.',
            btnText: '확인',
            subBtnText: '',
            onClose: onCloseModal,
            btnCallback: onCloseModal,
          },
        }),
      );
      queryClient.invalidateQueries({ queryKey: 'allStorage' });
    },
    onError: (error) => {
      console.log('에러', error);
    },
  });

  const onCloseModal = () => {
    dispatch(updateModal({ isActive: false, data: null, type: null }));
  };

  const { mutate: refundMutation, isLoading } = useMutation<
    IRefundResponse,
    AxiosError,
    IRefundPayload
  >((payload) => refundRegister(payload), {
    retry: false,
    onSuccess: (data) => {
      dispatch(refundIndexState(data));
      dispatch(reset());
      setIsRefund(true);
      setIsPayment(false);
      dispatch(updateLoading({ isLoading: false }));
    },
    onError: (error) => {
      //@ts-ignore
      const { code, message } = error.response?.data;
      switch (code) {
        case 'H0006':
          alert(
            '유치기관 만료일을 초과했습니다. 만료일 갱신 후 다시 시도해 주세요.',
          );
          break;
        default:
          if (message) {
            return alert(message);
          }
          return alert('오류가 발생했습니다. 잠시후 다시 시도해주세요.');
      }
      dispatch(updateLoading({ isLoading: false }));
    },
  });

  const onSubmit: SubmitHandler<ISubmitData> = (data) => {
    const { paymentCard, paymentCash, supply, supplyDate, touristName } = data;
    let totalPrice = 0;
    let totalRefund = 0;
    const newPaymentCard = paymentCard.replace(/(,)/g, '');
    const newPaymentCash = paymentCash.replace(/(,)/g, '');
    const newSupply = supply.filter(
      (item) => item.price && String(item.productType),
    );

    newSupply.forEach((item: ISupplyItem) => {
      item.productType = +item.productType;
      delete item.isCheck;
    });

    if (newSupply.length === 0) {
      return;
    }

    data.supply.forEach((item) => {
      item.price = item.price.replace(/(,)/g, '');
      totalPrice += Number(item.price);
    });
    totalRefund = getRefundAmount(totalPrice);

    if (totalPrice < 15000) {
      setErrorMessage((prev) => ({
        ...prev,
        isSubmit: true,
        message: '최소 금액 15,000원 이상 입니다.',
      }));
      return;
    }

    const payload: IRefundPayload = {
      touristName: touristName.toUpperCase(),
      supplyDate,
      totalRefund: String(totalRefund),
      totalPrice: String(totalPrice),
      paymentCard: String(newPaymentCard),
      paymentCash: String(newPaymentCash),
      touristId: String(touristIndex),
      orderInfoList: newSupply,
      paymentType: !isAutoPayment || isDirectPayment ? 'DIRECT' : 'ORIGINAL',
      isScanned,
      tempInformationId: temporaryStorage.tempInformationId,
    };
    dispatch(
      updateModal({
        isActive: true,
        type: 'PRICE_CHECK',
        data: {
          icon: '',
          title: '결제금액을 확인해주세요. ',
          content:
            convertToKoreanUnit(
              isAutoPayment && !isDirectPayment
                ? totalPrice - getRefundAmount(totalPrice)
                : totalPrice,
            ) + '원',
          btnText: '확인',
          subBtnText: '다시 입력',
          onClose: onCloseModal,
          btnCallback: () => {
            dispatch(updateLoading({ isLoading: true }));
            refundMutation(payload);
          },
        },
      }),
    );
  };

  const onClickStorage = () => {
    const {
      touristName,
      nationality,
      passportNumber,
      supplyDate,
      supply,
      birthYear,
      birthMonth,
      birthDay,
      paymentCard,
      paymentCash,
    } = methods.getValues();

    const payload = {
      hospitalId,
      supplyDate: supplyDate,
      nation: nationality,
      name: touristName.toUpperCase(),
      passportNumber,
      paymentCash,
      paymentCard,
      orderInfoList: supply,
      year: birthYear,
      month: birthMonth.value,
      day: birthDay,
      tempInformationId: temporaryStorage.tempInformationId,
    };
    storageMutation(payload);
  };
  const focusTouristNameWhenClickAnywhere = (e: any) => {
    const { id, tagName } = e.target;
    if (
      id !== 'no_focus' &&
      !['INPUT', 'BUTTON', 'LI', 'IMG', 'UL'].includes(tagName)
    ) {
      setFocus('touristName');
    }
  };

  return (
    <FormProvider {...methods}>
      <Container onClick={focusTouristNameWhenClickAnywhere}>
        <FlexWrap justifyContent='space-between' alignItems='center'>
          <Title>환급하기</Title>
          <FlexWrap gap='16px'>
            <FlexWrap gap='8px'>
              <Icon imgUrl={callIcon} width='24px' height='24px' />
              <Typography size='16px' fontWeight='500' lineHeight='24px'>
                02.6275.8011
              </Typography>
            </FlexWrap>
            <Link href='http://pf.kakao.com/_fFgsb/chat' target='_blank'>
              <Icon imgUrl={kakaoIcon} width='24px' height='24px' />
              <Typography size='16px' fontWeight='500' lineHeight='24px'>
                카카오톡 채널 문의하기
              </Typography>
            </Link>
          </FlexWrap>
        </FlexWrap>
        <TouristInput
          setLeafletModalInfo={setLeafletModalInfo}
          setIsRefund={setIsRefund}
          setIsPayment={setIsPayment}
          isExpired={isExpired}
        />
        <SupplyDetail />
        <AmountDetail
          isPayment={isPayment}
          setLeafletModalInfo={setLeafletModalInfo}
        />
        <FlexWrap justifyContent='flex-end' alignItems='center' gap='15px'>
          {Boolean(errorMessage.message) && (
            <ErrorMessage>{errorMessage.message}</ErrorMessage>
          )}
          <Button temporaryStorage onClick={onClickStorage}>
            임시저장
          </Button>
          <Button
            isValidate={isPassportCheck}
            disabled={!isPassportCheck || isLoading}
            onClick={handleSubmit(onSubmit)}
          >
            {isLoading ? <LoadingView /> : '확인'}
          </Button>
        </FlexWrap>
        {isFetchedStorageData && !isPassportCheck && (
          <Typography
            color='#ED0828'
            fontFamily='Pretendard'
            margin='-20px 0 0 auto'
          >
            [여권 확인] 버튼을 먼저 눌러주세요
          </Typography>
        )}
      </Container>
      {(isKioskStore || isAutoPayment) && leafletModalInfo.active && (
        <DirectModal
          isKioskStore={isKioskStore}
          leafletModalInfo={leafletModalInfo}
          setLeafletModalInfo={setLeafletModalInfo}
        />
      )}
    </FormProvider>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  gap: 36px;
  flex: 1;
  padding: 22px 40px 85px 40px;
  @media ${(props) => props.theme.laptop} {
    gap: 24px;
  }
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;

const Button = styled.button<{
  isValidate?: boolean;
  temporaryStorage?: boolean;
}>`
  width: 100px;
  height: 48px;
  border-radius: 4px;
  align-self: flex-end;
  font-weight: ${(props) => props.theme.fontWeight.medium};
  color: ${(props) =>
    props.isValidate
      ? props.theme.pointColors.white
      : props.theme.grayColors.scale2};
  background-color: ${(props) =>
    props.isValidate
      ? props.theme.grayColors.scale1
      : props.theme.grayColors.scale4};
  ${(props) =>
    props.temporaryStorage &&
    css`
      font-size: 16px;
      font-weight: 500;
      line-height: 24px;
      color: #246cf6;
      background-color: #fff;
      border: 1.5px solid #246cf6;
      border-radius: 8px;
    `}
`;
const ErrorMessage = styled.p`
  display: flex;
  justify-content: flex-end;
  color: ${(props) => props.theme.priamryColors.error};
`;
const Link = styled.a`
  display: flex;
  gap: 8px;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;
export default RefundContent;
