import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  hospitalId: 0,
  isLogged: false,
  touristIndex: '',
  refundIndex: '',
  medicalName: '',
  productTypes: [],
  isAutoPayment: '',
  hospitalType: '', // 유치기관을 소유한 병원인지 여부
  isKioskStore: false,
  hospitals: [], // 하위병원
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginUserState: (state, action) => {
      state.isLogged = action.payload;
    },
    touristIndexState: (state, action) => {
      state.touristIndex = action.payload.touristId;
    },
    refundIndexState: (state, action) => {
      state.refundIndex = action.payload.refundId;
    },
    paymentTypeState: (state, action) => {
      state.isAutoPayment = action.payload;
    },

    updateUserInfo: (state, action) => {
      return { ...state, ...action.payload };
    },
  },
});

export const {
  loginUserState,
  touristIndexState,
  refundIndexState,
  paymentTypeState,
  updateUserInfo,
} = userSlice.actions;
export default userSlice.reducer;
