import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';

import { getRefundRecordDetail } from '@/api/refund';
import { formatDateYMD } from '@/util/common';

const useRefundVatData = (refundRecordIndex: number) => {
  const [isToggle, setIsToggle] = useState(false);

  const { data: recordFiles, refetch } = useQuery<any[], null>(
    ['refundRecordDetail', refundRecordIndex],
    async () => await getRefundRecordDetail(refundRecordIndex),
    {
      enabled: false,
      retry: false,
    }
  );

  // 토글변화
  const changeToggle = () => {
    setIsToggle(!isToggle);
  };

  // 오늘날 기준으로 1주일전이면 New 아니면 Index 체크함수
  const checkNewFile = (date: string) => {
    const currentDate = new Date();
    const createDate = new Date(date);
    const timeDiff = +currentDate - +createDate;
    const weekTime = 7 * 24 * 60 * 60 * 1000;

    return timeDiff < weekTime;
  };

  // category에 따라 토글전 제목 결정하는 함수
  const checkFileName = (category: string) => {
    return category === 'MONTH'
      ? 'KTP 환급처리비용청구서 & 실적명세서'
      : 'KTP 부가가치세 신고자료';
  };

  // 등록일 텍스트 핸들링 함수
  const checkCreated = (date: string) => {
    const { year, month, day } = formatDateYMD(date);
    return year + '년 ' + month + '월 ' + day + '일';
  };

  // 전체 다운로드 함수
  const fileAllDownload = () => {
    if (!recordFiles) return;
    recordFiles.forEach(async (item) => {
      await fileDownload(item);
    });
  };

  // 파일 다운로드 함수
  const fileDownload = (file: any) => {
    fetch(file.s3Url)
      .then((response) => response.blob())
      .then((blob) => {
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = file.fileName;
        link.click();
        URL.revokeObjectURL(url);
      })
      .catch((error) => {
        console.error('Error downloading file:', error);
      });
  };

  // 자료명 태그 결정
  const monthHandle = (month: string) => {
    switch (month) {
      case '1Q':
        return '1분기';
      case '2Q':
        return '2분기';
      case '3Q':
        return '3분기';
      case '4Q':
        return '4분기';
      case '1H':
        return '상반기';
      case '2H':
        return '하반기';
      default:
        return month + '월';
    }
  };

  useEffect(() => {
    if (isToggle) {
      refetch();
    }
  }, [isToggle]);

  return {
    recordFiles,
    refetch,
    isToggle,
    checkNewFile,
    checkFileName,
    checkCreated,
    fileAllDownload,
    fileDownload,
    changeToggle,
    monthHandle,
  };
};

export default useRefundVatData;
