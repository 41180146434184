import React, { useEffect } from 'react';
import styled from 'styled-components';
import FlexWrap from '@/components/common/FlexWrap';
import ExcelTable from './ExcelTable';
import ServiceGuide from './ServiceGuide';
import ContentToCollect from './ContentToCollect';

interface IProps {
  isDetailModal?: boolean;
  touristIndex: number;
  refundIndex: number;
}

function PrintElement({ refundIndex, touristIndex, isDetailModal }: IProps) {
  return (
    <Container dir='column' isDetailModal={isDetailModal}>
      <ExcelTable touristIndex={touristIndex} refundIndex={refundIndex} />
      {/* <ContentToCollect />
      <ServiceGuide /> */}
    </Container>
  );
}

const Container = styled(FlexWrap)<{ isDetailModal?: boolean }>`
  width: ${(props) => (props.isDetailModal ? '100%' : '1200px')};
`;

export default PrintElement;
