import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import styled from 'styled-components';

import checkIcon from '@/assets/common/check.png';
interface IProps {
  isDisable?: boolean;
  isCheck: boolean;
  onClick: () => void;
}

function Checkbox({ isDisable, isCheck, onClick }: IProps) {
  return (
    <CheckBox isCheck={isCheck} disabled={isDisable} onClick={onClick}>
      {isCheck && !isDisable && <CheckBoxImage src={checkIcon} />}
    </CheckBox>
  );
}

const CheckBox = styled.button<{ isCheck: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  cursor: pointer;
  border-radius: 2px;
  border: ${(props) =>
    props.isCheck ? 'none' : `1px solid ${props.theme.grayColors.scale4}`};
  background-color: ${(props) =>
    props.isCheck
      ? props.theme.priamryColors.primary
      : props.theme.pointColors.white};
  :disabled {
    cursor: auto;
    background-color: ${(props) => props.theme.grayColors.scale4};
  }
`;

const CheckBoxImage = styled.img`
  width: 12px;
  height: 8px;
  text-align: center;
`;

export default Checkbox;
