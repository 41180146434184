import { IExcelResponse, IFormattedExcel } from '@/types/refund';
import { format } from 'date-fns';

export const removeComma = (x: string) => {
  return Number(x?.replace(/,/g, ''));
};

export const getComma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

// 한글로 단위 형식
export const convertToKoreanUnit = (number: number) => {
  if (typeof number !== 'number' || isNaN(number)) {
    return '';
  }

  const units = ['', '만 ', '억 ', '조'];
  let result = '';
  let unitIndex = 0;

  while (number > 0) {
    const remainder = number % 10000;
    number = Math.floor(number / 10000);

    if (remainder > 0) {
      let remainderStr = remainder.toString();
      if (unitIndex === 0 && remainderStr.length >= 4) {
        // 천 단위로 콤마 추가
        remainderStr = remainderStr.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
      }
      result = `${remainderStr}${units[unitIndex]}${result}`;
    }

    unitIndex++;
  }

  return result;
};

export const getSupplyPaymentPrice = (num: string) => {
  return Math.floor((+num / 11) * 10);
};

export const attachedHyphens = (dateNumber: string) => {
  return dateNumber
    .replace(/[^0-9]/g, '')
    .replace(/^(\d{0,2})(\d{0,2})(\d{0,2})/g, '$1-$2-$3')
    .replace(/(\-{1,2})$/g, '');
};

// 한글만 입력 안되도록
export const validateEnglishInput = (text: string) => {
  return text.replace(/[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/, '');
};

// 숫자만 입력 되도록 + 하이픈
export const attachedHyphensDate = (dateNumber: string) => {
  return dateNumber
    .replace(/[^0-9]/g, '')
    .replace(/(\d{4})(\d{2})(\d{2})/, '$1-$2-$3');
};

export const attachedDot = (dateNumber: string) => {
  return (
    dateNumber.substring(0, 4) +
    '.' +
    dateNumber.substring(4, 6) +
    '.' +
    dateNumber.substring(6, 8)
  );
};
export const formatDotDate = (date: string) => {
  const newDate = new Date(date);
  return format(newDate, 'yyyy.MM.dd');
};
export const getVatPaymentPrice = (
  originPrice: string,
  paymentPrice: string
) => {
  return +originPrice - +paymentPrice;
};
export const comma = (x: string | number) => {
  return String(x).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};
export const getFormattedTableData = (data: IExcelResponse) => {
  return data.details.map((item) => {
    const {
      doubleEyeDto,
      rhinoplastyDto,
      breastDto,
      liposuctionDto,
      faceLiftDto,
      facialBoneDto,
      teethWhiteningDto,
      orthognathicDto,
      nevusDto,
      pimpleDto,
      hairRemovalDto,
      hairTransplantationDto,
      tatooDto,
      lipolysisDto,
      skinDto,
      othersDto,
    } = item.productAllDto;

    return {
      ...item,
      productAllDto: [
        {
          label: ' ① 쌍커풀수술 Double Eye-lid',
          price: doubleEyeDto?.price,
          vat: doubleEyeDto?.vat,
        },
        {
          label: ' ② 코성형수술 Rhinoplasty',
          price: rhinoplastyDto?.price,
          vat: rhinoplastyDto?.vat,
        },
        {
          label: ' ③ 유방수술 Breast Surgery',
          price: breastDto?.price,
          vat: breastDto?.vat,
        },
        {
          label: ' ④ 지방흡인술 Liposuction',
          price: liposuctionDto?.price,
          vat: liposuctionDto?.vat,
        },
        {
          label: ' ⑤ 주름살제거술 Face Lift',
          price: faceLiftDto?.price,
          vat: faceLiftDto?.vat,
        },
        {
          label: ' ⑥ 안면윤곽술 Facial bone contouring ',
          price: facialBoneDto?.price,
          vat: facialBoneDto?.vat,
        },
        {
          label: ' ⑦ 치아성형술 Teeth Whitening, Laminate, gingivoplasty',
          price: teethWhiteningDto?.price,
          vat: teethWhiteningDto?.vat,
        },
        {
          label: ' ⑧ 악안면 교정술 Orthognathic surgery',
          price: orthognathicDto?.price,
          vat: orthognathicDto?.vat,
        },
        {
          label:
            ' ⑨ 색소모반ㆍ주근깨ㆍ흑색점ㆍ기미치료술 Nevus pigmentosus, Freckles, Lentigo, Chloasma',
          price: nevusDto?.price,
          vat: nevusDto?.vat,
        },
        {
          label: ' ⑩ 여드름 치료술 Pimple',
          price: pimpleDto?.price,
          vat: pimpleDto?.vat,
        },
        {
          label: ' ⑪ 제모술 Hair removal',
          price: hairRemovalDto?.price,
          vat: hairRemovalDto?.vat,
        },
        {
          label: ' ⑫ 탈모치료술, 모발이식술 Hair loss , Hair transplantation',
          price: hairTransplantationDto?.price,
          vat: hairTransplantationDto?.vat,
        },
        {
          label:
            ' ⑬ 문신술 및 문신제거술, 피어싱 Tatoo, Tattoo removal, piercing ',
          price: tatooDto?.price,
          vat: tatooDto?.vat,
        },
        {
          label: ' ⑭ 지방융해술 Lipolysis',
          price: lipolysisDto?.price,
          vat: lipolysisDto?.vat,
        },
        {
          label: `⑮ 피부재생술, 피부미백술, 항노화치료술 및 모공축소술\nSkin regeneration, Skin Whitening, Anti-aging, Pore tightening`,
          price: skinDto?.price,
          vat: skinDto?.vat,
        },
        {
          label: ' ⑯ 기타(\t\t\t) Others',
          price: othersDto?.price,
          vat: othersDto?.vat,
        },
      ],
    };
  });
};
