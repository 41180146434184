import styled from 'styled-components';
import HomeContent from '@/components/home/HomeContent';
import Footer from '@/components/common/Footer';
import RenewalNotification from '@/components/common/RenewalNotification';
import WarningExpired from '@/components/common/WarningExpired';
import useGetGlobalStatus from '@/hooks/useGetGlobalStatus';

function Home() {
	const { isExpiryWarning, globalStatus } = useGetGlobalStatus();

	return (
		<Container>
			<div>
				{isExpiryWarning ? (
					<WarningExpired expiryDate={globalStatus?.promotionExpiryDate} />
				) : (
					<RenewalNotification />
				)}
			</div>
			<HomeContent />
			<Footer />
		</Container>
	);
}

const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	background-color: ${(props) => props.theme.blueColors.scale3};
	min-width: 1200px;
	height: 100vh;
	flex: 1;
	overflow: auto;
	position: relative;
`;

export default Home;
