import React, { Dispatch, SetStateAction } from 'react';
import styled from 'styled-components';

import { IMedicalResponse } from '@/types/medical';
import FlexWrap from '../common/FlexWrap';
import Text from '../common/Text';

interface IProps {
  medicalData: IMedicalResponse;
  setIsPasswordChange: Dispatch<SetStateAction<boolean>>;
}

function MedicalInfo({ medicalData, setIsPasswordChange }: IProps) {
  const onChangePassword = () => {
    setIsPasswordChange(true);
  };

  return (
    <Container>
      <FlexWrap dir='column' gap='12px' flex='1'>
        <InputContainer>
          <Text text='사업자등록증번호' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.businessNumber} />
        </InputContainer>
        <InputContainer>
          <Text text='의료기관명' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.hospitalName} />
        </InputContainer>
        <InputContainer>
          <Text text='대표자명' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.managerName} />
        </InputContainer>
      </FlexWrap>
      <FlexWrap dir='column' gap='12px' flex='1'>
        <InputContainer>
          <Text text='전화번호' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.hospitalTelNumber} />
        </InputContainer>
        <InputContainer>
          <Text text='외국인 환자 유치기관 등록 번호' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.taxFreeNumber} />
        </InputContainer>
        <InputContainer>
          <Text text='비밀번호' fontWeight='500' />
          <Button onClick={onChangePassword}>비밀번호 변경</Button>
        </InputContainer>
      </FlexWrap>
      <FlexWrap dir='column' flex='2'>
        <InputContainer address>
          <Text text='의료기관 소재지' fontWeight='500' />
          <Input value={medicalData?.hospitalInfoDto.hospitalAddress} />
        </InputContainer>
      </FlexWrap>
    </Container>
  );
}

const Container = styled(FlexWrap)`
  display: flex;
  gap: 80px;
  padding: 28px 20px;
  background-color: ${(props) => props.theme.pointColors.white};
`;

const InputContainer = styled.div<{ address?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: 100%;
  max-width: ${(props) => (props.address ? '492px' : '283px')};
`;

const Input = styled.input.attrs({ disabled: true })`
  padding: 13px 10px;
  border-radius: 4px;
  width: 100%;
  color: ${(props) => props.theme.grayColors.scale2};
  border: 2px solid ${(props) => props.theme.grayColors.scale3};
`;

const Button = styled.button`
  padding: 13px 10px;
  border-radius: 2px;
  color: ${(props) => props.theme.pointColors.white};
  background-color: ${(props) => props.theme.grayColors.scale1};
`;

export default MedicalInfo;
