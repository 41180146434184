export const getRefundAlertMessage = (message: string) => {
  const [errorCode, caseMessage, divisionCode] = message.split(':');
  console.log('divisionCode', divisionCode);

  if (divisionCode === 'E0011' || divisionCode === 'E0012') {
    return `국가 여행자 정보 시스템\n통신이 원활하지 않습니다.\n고객센터 문의 - 02-6275-8011`;
  }
  if (divisionCode === 'E0013') {
    switch (errorCode) {
      case '0047':
        return '판매일이 고객의 최초 입국일보다\n이전 날짜입니다.';
      case '0048':
        return '입국정보가 없습니다.\n여권번호를 다시 확인해주세요.';
      case '0049':
        return '이미 출국한 고객입니다.';
      case '2001':
        return '즉시환급 통합 한도가 초과되었습니다.';
      case '2002':
        return '즉시환급 한도가 초과되었습니다.\n사후환급 서비스를 이용해주세요.';
      case '2003':
      case '2004':
      case '2005':
      case '2006':
      case '2007':
        return `선별검사 대상자입니다.\n(코드 ${errorCode}번)`;
      case '2008':
        return '즉시환급한도원장에 등록된\n여권번호가 아닙니다.';
      case '2009':
        return '기존 한도원장이 초기화되지\n않았습니다(미출국자)';
      case '2010':
        return '한도원장과 거래내역이\n일치하지 않습니다.';
      case '2011':
        return '이미 승인된 건입니다.';
      case '2099':
        return '즉시환급 서비스가 일시중지 상태입니다.\n고객센터로 문의해주세요.\n02-6275-8011 ';
      case '3006':
        return '이전 입국시 환급완료된 건으로,\n환급취소가 불가능합니다.';
      case '4001':
        return `국적코드와 여권번호가 일치하지 않습니다. (코드 ${errorCode}번)`;
      case '4003':
        return `성명과 여권번호가 일치하지 않습니다. (코드 ${errorCode})번`;
      case '4005':
        return `유효하지 않는 여권번호입니다. 다시 시도해주세요.\n(코드 ${errorCode}번)`;
      case '4006':
        return `외국인 관광객이 아닙니다.(해외 체류 3개월 경과한 국내국적자)\n(코드 ${errorCode}번)`;
      case '4007':
        return `입국한지 6개월 경과로 환급 대상자가 아닙니다.\n(코드 ${errorCode}번)`;
      case '4008':
      case '4009':
        return `한국 국적자로 환급 대상자가 아닙니다.\n(코드 ${errorCode}번)`;
      default:
        return `국가 여행자 정보 시스템\n통신이 원활하지 않습니다.\n고객센터 문의 - 02-6275-8011`;
    }
  }
  return `국가 여행자 정보 시스템\n통신이 원활하지 않습니다.\n고객센터 문의 - 02-6275-8011`;
};
