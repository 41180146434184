import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import ReactPaginate from 'react-paginate';
import './paging.css';

interface IProps {
  totalPage: number;
  pageNumber?: number;
  setPageNumber: Dispatch<SetStateAction<number>>;
}

interface IPage {
  selected: number;
}

const Paging = ({ pageNumber, setPageNumber, totalPage }: IProps) => {
  const onChange = ({ selected }: IPage) => {
    setPageNumber(selected);
  };

  return (
    <ReactPaginate
      pageCount={totalPage}
      marginPagesDisplayed={4}
      pageRangeDisplayed={5}
      previousLabel={'<'}
      nextLabel={'>'}
      onPageChange={onChange}
      containerClassName={'paginationBttns'}
      previousLinkClassName={'priviosBttn'}
      activeLinkClassName={'nextBttn'}
      disabledClassName={'paginationDisabled'}
      activeClassName={'paginationActive'}
      forcePage={pageNumber}
    />
  );
};

export default Paging;
