import React from 'react';
import { attachedHyphensDate } from '@/util/format';
import { getNumberFullTime } from '@/util/getCurrentTime';
import { useFormContext } from 'react-hook-form';

const useValidate = () => {
  const formContext = useFormContext();

  // 생년월일 숫자만 입력가능
  const validateNumericInput = (value: string, regex: RegExp) => {
    if (value.length < 2) {
      return value.replace(regex, '');
    }
    return value.replace(/[^0-9]/g, '');
  };

  // 올바른 생년월일 확인함수
  const checkCorrectDate = (year: string, month: string, day: string) => {
    const regex =
      /^(19[0-9]{2}|20[0-2][0-9])(0[1-9]|1[0-2])(0[1-9]|[1-2][0-9]|3[0-1])$/;
    const birthDate = attachedHyphensDate(year + month + day);

    if (regex.test(birthDate.replaceAll('-', ''))) {
      if (+month === 2) {
        if ((+year % 4 === 0 && +year % 100 !== 0) || +year % 400 === 0) {
          return +day <= 29;
        } else {
          return +day <= 28;
        }
      } else if ([1, 3, 5, 7, 8, 10, 12].includes(+month)) {
        return +day <= 31;
      } else {
        return +day <= 30;
      }
    } else {
      return false;
    }
  };

  // 공급날짜 확인
  const checkSupplyDate = (supplyDate: string) => {
    const toDayDate = getNumberFullTime(new Date());
    const currentSupplyDate = supplyDate.replaceAll('-', '');

    const oneMonthBefore = new Date();
    oneMonthBefore.setMonth(oneMonthBefore.getMonth() - 1);
    const oneMonthBeforeDate = getNumberFullTime(oneMonthBefore);

    const setError = formContext.setError;
    if (
      +currentSupplyDate > +toDayDate ||
      +currentSupplyDate < +oneMonthBeforeDate
    ) {
      setError('supplyDate', {
        type: 'manual',
        message: '시술일자를 다시 확인해주세요',
      });
      return false;
    }
    return true;
  };

  return { validateNumericInput, checkCorrectDate, checkSupplyDate };
};

export default useValidate;
