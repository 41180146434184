import React from 'react';
import styled from 'styled-components';
import FlexWrap from './FlexWrap';
import ICON_WARNING from '@/assets/common/error.png';

type Props = {
	onClose: () => void;
};

function ExpiredDateModal({ onClose }: Props) {
	return (
		<Container>
			<Modal>
				<ModalTitle>
					<img src={ICON_WARNING} width={28} height={28} alt=''></img>
					외국인 환자 유치기관 등록 만료
				</ModalTitle>
				<Description>
					외국인 환자 유치기관 등록기간이 만료되어 환급이 불가합니다.
					<br />
					만료일 갱신 후 KTP 고객센터 (02-6275-8011)로 갱신 여부를 알려주세요.
				</Description>
				<BtnContainer>
					<CloseBtn onClick={onClose} active={true} disabled={false}>
						닫기
					</CloseBtn>
					<RenewBtn
						href='https://www.medicalkorea.or.kr/korp/main.do'
						target='_blank'
						onClick={onClose}
					>
						갱신하기
					</RenewBtn>
				</BtnContainer>
			</Modal>
		</Container>
	);
}

export default ExpiredDateModal;

const Container = styled.div`
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 200;
	display: flex;
	justify-content: center;
	align-items: center;
`;

const Modal = styled(FlexWrap)`
	padding: 32px;
	flex-direction: column;
	width: 500px;
	background-color: white;
	border-radius: 16px;
	min-width: 400px;
`;

const ModalTitle = styled.h3`
	display: flex;
	align-items: center;
	gap: 8px;
	color: #030303;
	font-size: 21px;
	font-weight: 700;
	line-height: normal;
	letter-spacing: -0.42px;
`;

const Description = styled.p`
	margin: 16px 0;
	color: #5f6165;
	font-size: 14px;
	font-weight: 500;
	line-height: 150%;
	letter-spacing: -0.28px;
`;

const BtnContainer = styled.div`
	display: flex;
	gap: 24px;
	width: 100%;
`;

const CloseBtn = styled.button<{ colored?: boolean; active: boolean }>`
	height: 52px;
	flex: 1;
	background-color: ${(props) =>
		props.active ? (props.colored ? '#246cf6' : '#E5E6E8') : '#D3E2FD'};
	color: ${(props) =>
		props.active ? (props.colored ? '#fff' : '#3A3B3E') : '#fff'};
	border: 1px solid #246cf6;
	border: 1px solid ${(props) => (props.active ? '#E5E6E8' : '#D3E2FD')};
	border-radius: 8px;
	font-size: 16px;
	line-height: 150%;
	padding: 14px 20px;
	:hover {
		opacity: 0.85;
	}
`;

const RenewBtn = styled.a`
	height: 52px;
	flex: 1;
	border: 1px solid #246cf6;
	color: #fff;
	background-color: #246cf6;
	border-radius: 8px;
	font-size: 16px;
	line-height: 150%;
	text-align: center;
	padding: 14px 20px;
	:hover {
		opacity: 0.85;
	}
`;
