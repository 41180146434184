export const SupplyHeader = [
  { id: 1, title: 'No.', flex: 1 },
  { id: 2, title: '시술명', flex: 4 },
  { id: 3, title: '공급가액', flex: 3 },
  { id: 4, title: '부가세액', flex: 3 },
  { id: 5, title: '의료금액', flex: 3 },
  { id: 6, title: '행 초기화', flex: 1.5 },
  { id: 7, title: '행 삭제', flex: 1.5 },
];

export const ProductTypeList = [
  { key: 0, value: '쌍커풀수술', name: 'DoubleEye' },
  { key: 1, value: '코성형수술', name: 'Rhinoplasty' },
  { key: 2, value: '유방수술', name: 'Breast' },
  { key: 3, value: '지방흡입술', name: 'Liposuction' },
  { key: 4, value: '주름살제거술', name: 'FaceLift' },
  { key: 5, value: '안면윤곽술', name: 'FacialBone' },
  { key: 6, value: '치아성형술', name: 'TeethWhitening' },
  { key: 7, value: '악안면 교정술', name: 'Orthognathic' },
  { key: 8, value: '색소모반ᆞ주근깨ᆞ흑색점ᆞ기미치료술', name: 'Nevus' },
  { key: 9, value: '여드름 치료술', name: 'Pimple' },
  { key: 10, value: '제모술', name: 'HairRemoval' },
  { key: 11, value: '탈모치료술, 모발이식술', name: 'HairTransplantation' },
  { key: 12, value: '문신술 및 문신제거술, 피어싱', name: 'Tatoo' },
  { key: 13, value: '지방융해술', name: 'Lipolysis' },
  {
    key: 14,
    value: '피부재생술, 피부미백술, 항노화치료술 및 모공축소술',
    name: 'Skin',
  },
  {
    key: 15,
    value: '기타',
    name: 'Others',
  },
];

export const KioskProductTypeList = [
  { key: 12, value: '문신술 및 문신제거술, 피어싱', name: 'Tatoo' },
  {
    key: 14,
    value: '피부재생술, 피부미백술, 항노화치료술 및 모공축소술',
    name: 'Skin',
  },
  { key: 8, value: '색소모반ᆞ주근깨ᆞ흑색점ᆞ기미치료술', name: 'Nevus' },
  { key: 13, value: '지방융해술', name: 'Lipolysis' },
  { key: 9, value: '여드름 치료술', name: 'Pimple' },
  {
    key: 15,
    value: '기타',
    name: 'Others',
  },
];
