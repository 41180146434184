import { loginUserState } from '@/store/modules/user';
import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
} from 'axios';
import store from '@/store';

const onFullfilled = (response: AxiosResponse<any>) => {
  return response;
};

const onRejected = async (error: AxiosError<any>) => {
  const errorCode = error?.response?.data?.code;
  if (['A0002', 'A0003'].includes(errorCode)) {
    localStorage.setItem('loginToken', '');
    localStorage.removeItem('name');
    localStorage.removeItem('profileImg');
    window.location.href = '/';
    store.dispatch(loginUserState(false));
  }
  return Promise.reject(error);
};

const initialization = (config: AxiosRequestConfig): AxiosInstance => {
  const axiosInstance = axios.create(config);

  axiosInstance.interceptors.request.use(async (config: any) => {
    const token = localStorage.getItem('loginToken');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  axiosInstance.interceptors.response.use(onFullfilled, onRejected);

  return axiosInstance;
};

export default initialization;
