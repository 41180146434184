import React from 'react';
import styled from 'styled-components';
import NoticeList from './NoticeList';

function NoticeContent() {
  return (
    <Container>
      <Title>공지사항</Title>
      <NoticeList />
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 28px;
  padding: 22px 40px 85px 40px;
`;

const Title = styled.h1`
  font-size: ${(props) => props.theme.fontSize.xLarge};
  font-weight: ${(props) => props.theme.fontWeight.bold};
  line-height: ${(props) => props.theme.lineHeight.xLarge};
  @media ${(props) => props.theme.laptop} {
    font-size: ${(props) => props.theme.fontSize.large};
    line-height: ${(props) => props.theme.lineHeight.large};
  }
`;

export default NoticeContent;
