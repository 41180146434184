import styled, { css } from 'styled-components';

import '@/styles/calendar.css';
import useDeleteKioskVideo from '@/hooks/useDeleteKioskVideo';
import LoadingView from '@/components/common/LoadingView';
import { useAppSelector } from '@/hooks/useReduxHooks';

type Props = {
  fileName: string;
  kioskVideoId: number;
  onClose: () => void;
};
function DeleteModal({ fileName, kioskVideoId, onClose }: Props) {
  const { isKioskStore } = useAppSelector((state) => state.user);
  const { mutate, isLoading } = useDeleteKioskVideo({ onClose });

  const onDelete = () => {
    const paylaod = {
      kioskId: isKioskStore,
      kioskVideoId,
      type: 'HOSPITAL',
    };
    mutate(paylaod);
  };
  return (
    <BackDrop>
      {isLoading ? (
        <LoadingContainer>
          <LoadingView />
        </LoadingContainer>
      ) : (
        <Container>
          <Title>동영상 삭제하기 </Title>
          <Content>동영상을 삭제합니다.</Content>
          <FileName>{fileName}</FileName>
          <ButtonWrapper>
            <Button onClick={onClose}>취소</Button>
            <Button colored onClick={onDelete}>
              삭제하기
            </Button>
          </ButtonWrapper>
        </Container>
      )}
    </BackDrop>
  );
}

const BackDrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  z-index: 1;
  background-color: rgba(33, 33, 33, 0.1);
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 600px;
  height: 270px;
  padding: 36px 40px;
  position: relative;
  border-radius: 8px;
  background-color: ${(props) => props.theme.pointColors.white};
  filter: ${(props) => props.theme.boxShadow};
`;
const LoadingContainer = styled(Container)`
  justify-content: center;
  align-items: center;
`;
const Gif = styled.img`
  width: 88px;
  height: 88px;
  margin-bottom: 16px;
`;
const Title = styled.h2`
  font-size: 21px;
  font-weight: 700;
  line-height: 150%;
  margin-bottom: 16px;
`;
const Content = styled.p`
  font-size: 14px;
  font-weight: 500;
  color: #5f6165;
`;
const FileName = styled.div`
  color: #5f6165;
  font-size: 12px;
  padding: 12px;
  width: 100%;
  background-color: #f5f6f7;
  border-radius: 4px;
  margin-top: 16px;
  position: relative;
`;
const ButtonWrapper = styled.div`
  display: flex;
  width: 100%;
  gap: 18px;
  margin-top: 16px;
`;
const Button = styled.button<{ colored?: boolean }>`
  ${(props) =>
    props.colored
      ? css`
          background-color: #ff2d55;
          color: #ffffff;
        `
      : css`
          background-color: #e5e6e8;
          color: #3a3b3e;
        `}
  font-size: 14px;
  line-height: 150%;
  padding: 15px 42px;
  font-weight: 600;
  border-radius: 6px;
  flex: 1;
  :hover {
    opacity: 0.6;
  }
`;
export default DeleteModal;
